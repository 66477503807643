import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateAbout as onUpdateAbout,
  getAbout as onGetAbout,
} from "store/contacts/actions";
import toastr from 'toastr'; // toastr kütüphanesini içe aktarın
import 'toastr/build/toastr.min.css'; // toastr stillerini içe aktarın

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import Dropzone from 'react-dropzone-uploader';
import { Base64 } from 'js-base64';
import axios from "axios";


function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file; // loader.file bir Promise nesnesi döndürür

          // Burada file değişkeni Promise'ın çözüldüğü (fulfilled) durumda, yani File nesnesini içerir
          const body = new FormData();
          body.append('dataFile', file);
          console.log("file1c2313",file);

          try {
            const response = await axios.request({
              method: "POST",
              url: `https://berlinadmin.stechomeyazilim.info:9091/uploadfile`,
              data: body, // axios'ta "data" kullanın, "body" değil
              headers: {
                "Content-Type": "multipart/form-data"
              }
            });

            resolve({
              default: `https://megasubdomain.stechomeyazilim.info/${response.data.file.filename}`
            });
          } catch (error) {
            // Hata oluştuğunda throw ile hata fırlatın
            throw error;
          }

          // File nesnesini kullanarak istediğiniz işlemleri yapabilirsiniz
        } catch (error) {
          reject(error);
        }
      });
    },
    abort: () => {}
  };
}


function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}


const EcommerceAddProduct = () => {
  document.title = "Hakkımızda Bilgileri | TUSF Admin Paneli";

  const dispatch = useDispatch();

  const [getContactEdit, setContactEdit] = useState(null);
  const [getHtmlDataLong, setHtmlDataLong] = useState(null);

  const [getFileName, setFileName] = useState(null);
  const [getFileVideo, setFileVideo] = useState(null);


  const [getHtmlDataKVKK1, setHtmlDataKvkk1] = useState(null);
  const [getHtmlDataKVKK2, setHtmlDataKvkk2] = useState(null);
  const [getHtmlDataKVKK3, setHtmlDataKvkk3] = useState(null);


  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }
  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }


  const getUploadParamsVideo = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }


  const showToast= async (type) => {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla güncellendi."
    } else{
       ele = "error"
       message = "type"
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      number: (getContactEdit && getContactEdit.PhoneNumber) || '',

      lat: (getContactEdit && getContactEdit.Lat) || '',
      long: (getContactEdit && getContactEdit.Long) || '',
      instagram: (getContactEdit && getContactEdit.Instagram_Address) || '',
      twitter: (getContactEdit && getContactEdit.Twitter_Address) || '',
      facebook: (getContactEdit && getContactEdit.Facebook_Address) || '',
      youtube: (getContactEdit && getContactEdit.Youtube_Address) || '',
      iban: (getContactEdit && getContactEdit.Iban) || '',
      number2: (getContactEdit && getContactEdit.PhoneNumber2) || '',
      number3: (getContactEdit && getContactEdit.PhoneNumber3) || '',

      kep: (getContactEdit && getContactEdit.KepAddress) || '',
    },
    validationSchema: Yup.object({
     
    }),
    onSubmit: (values) => {
												
      
      const updateOrder = {
        ID: users ? users[0].ID : 0,
        About_Desc: Base64.encode(getHtmlDataLong),
        Lat: parseFloat(values.lat),
        Long: parseFloat(values.long),
        PhoneNumber: values.number.toString(),
        Logo:getFileName,
        Video : getFileVideo,
        Twitter_Address:values.twitter,
        Facebook_Address:values.facebook,
        Instagram_Address:values.instagram,
        Youtube_Address:values.instagram,
        Iban:values.iban,
        PhoneNumber2:values.number2,
        PhoneNumber3:values.number3,
        Kvkk1:Base64.encode(getHtmlDataKVKK1),
        Kvkk2:Base64.encode(getHtmlDataKVKK2),
        Kvkk3:Base64.encode(getHtmlDataKVKK3),
        KepAddress: values.kep
        //CompanyName:
        //Title
       // AboutAdress: values.address,
      
       // About_KVKK:Base64.encode(getHtmlDataKVKK),

        //WebAdress: values.web,
       // TermsofUse: Base64.encode(getHtmlDataLongSecond),

      };
     

      dispatch(onUpdateAbout(updateOrder));
      showToast(true)

      dispatch(onGetAbout());

     // validation.resetForm();

    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.about,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetAbout());

    }
  }, [dispatch, users]);

  useEffect(() => {
    if (users.length > 0) {
      setContactEdit(users[0]);
      setFileName(users[0].Logo);
      setFileVideo(users[0].Video);


      if(users[0].Kvkk1 != null){
        setHtmlDataKvkk1(Base64.decode(users[0].Kvkk1))
      }
      if(users[0].Kvkk2 != null){
        setHtmlDataKvkk2(Base64.decode(users[0].Kvkk2))
      }

      if(users[0].Kvkk3 != null){
        setHtmlDataKvkk3(Base64.decode(users[0].Kvkk3))
      }

    }
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);


  const handleChangeStatusVideo = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileVideo(result.file.filename)

        }
      }
    }
  }

  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Hakkımızda Bilgileri" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
              

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>


                    <div className="mb-3">
                        <Label htmlFor="productname">Kep Adresi</Label>
                        <Input
                          id="kep"
                          name="kep"
                          type="text"
                          className="form-control"
                          placeholder="Kep Adresi"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.kep || ""}
                          invalid={
                            validation.touched.kep && validation.errors.kep ? true : false
                          }
                        />
                        {validation.touched.kep && validation.errors.kep ? (
                          <FormFeedback type="invalid">{validation.errors.kep}</FormFeedback>
                        ) : null}

                      </div>

                    <div className="mb-3">
                        <Label htmlFor="productname">Twitter Adres</Label>
                        <Input
                          id="twitter"
                          name="twitter"
                          type="text"
                          className="form-control"
                          placeholder="Twitter Adresi"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.twitter || ""}
                          invalid={
                            validation.touched.twitter && validation.errors.twitter ? true : false
                          }
                        />
                        {validation.touched.twitter && validation.errors.twitter ? (
                          <FormFeedback type="invalid">{validation.errors.twitter}</FormFeedback>
                        ) : null}

                      </div>


                      <div className="mb-3">
                        <Label htmlFor="productname">Facebook Adres</Label>
                        <Input
                          id="facebook"
                          name="facebook"
                          type="text"
                          className="form-control"
                          placeholder="Facebook Adresi"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.facebook || ""}
                          invalid={
                            validation.touched.facebook && validation.errors.facebook ? true : false
                          }
                        />
                        {validation.touched.facebook && validation.errors.facebook ? (
                          <FormFeedback type="invalid">{validation.errors.facebook}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="productname">Instagram Adres</Label>
                        <Input
                          id="instagram"
                          name="instagram"
                          type="text"
                          className="form-control"
                          placeholder="Instagram Adresi"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.instagram || ""}
                          invalid={
                            validation.touched.instagram && validation.errors.instagram ? true : false
                          }
                        />
                        {validation.touched.instagram && validation.errors.instagram ? (
                          <FormFeedback type="invalid">{validation.errors.instagram}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="productname">Youtube Adres</Label>
                        <Input
                          id="youtube"
                          name="youtube"
                          type="text"
                          className="form-control"
                          placeholder="Youtube Adresi"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.youtube || ""}
                          invalid={
                            validation.touched.youtube && validation.errors.youtube ? true : false
                          }
                        />
                        {validation.touched.youtube && validation.errors.youtube ? (
                          <FormFeedback type="invalid">{validation.errors.youtube}</FormFeedback>
                        ) : null}

                      </div>



                      <div className="mb-3">
                        <Label htmlFor="productname">Iban</Label>
                        <Input
                          id="iban"
                          name="iban"
                          type="text"
                          className="form-control"
                          placeholder="Mail Adresi"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.iban || ""}
                          invalid={
                            validation.touched.iban && validation.errors.iban ? true : false
                          }
                        />
                        {validation.touched.iban && validation.errors.iban ? (
                          <FormFeedback type="invalid">{validation.errors.iban}</FormFeedback>
                        ) : null}

                      </div>


                      <div className="mb-3">
                        <Label htmlFor="productname">Enlem</Label>
                        <Input
                          id="lat"
                          name="lat"
                          type="text"
                          className="form-control"
                          placeholder="Enlem"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lat || ""}
                          invalid={
                            validation.touched.lat && validation.errors.lat ? true : false
                          }
                        />
                        {validation.touched.lat && validation.errors.lat ? (
                          <FormFeedback type="invalid">{validation.errors.lat}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="productname">Boylam</Label>
                        <Input
                          id="long"
                          name="long"
                          type="text"
                          className="form-control"
                          placeholder="Boylam"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.long || ""}
                          invalid={
                            validation.touched.long && validation.errors.long ? true : false
                          }
                        />
                        {validation.touched.long && validation.errors.long ? (
                          <FormFeedback type="invalid">{validation.errors.long}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="productname"> Adres </Label>
                        <Input
                          id="address"
                          name="address"
                          type="address"
                          className="form-control"
                          placeholder=" Adres"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address || ""}
                          invalid={
                            validation.touched.address && validation.errors.address ? true : false
                          }
                        />
                        {validation.touched.address && validation.errors.address ? (
                          <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                        ) : null}

                      </div>


                      <div className="mb-3">
                        <Label htmlFor="productname">Telefon Numarası 1</Label>
                        <Input
                          id="number"
                          name="number"
                          type="tel"
                          className="form-control"
                          placeholder="Tel Numarası"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.number || ""}
                          invalid={
                            validation.touched.number && validation.errors.number ? true : false
                          }
                        />
                        {validation.touched.number && validation.errors.number ? (
                          <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="productname">Telefon Numarası 2</Label>
                        <Input
                          id="number2"
                          name="number2"
                          type="tel"
                          className="form-control"
                          placeholder="Tel Numarası"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.number2 || ""}
                          invalid={
                            validation.touched.number2 && validation.errors.number2 ? true : false
                          }
                        />
                        {validation.touched.number2 && validation.errors.number2 ? (
                          <FormFeedback type="invalid">{validation.errors.number2}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label htmlFor="productname">Telefon Numarası 3</Label>
                        <Input
                          id="number3"
                          name="number3"
                          type="tel"
                          className="form-control"
                          placeholder="Tel Numarası"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.number3 || ""}
                          invalid={
                            validation.touched.number3 && validation.errors.number3 ? true : false
                          }
                        />
                        {validation.touched.number3 && validation.errors.number3 ? (
                          <FormFeedback type="invalid">{validation.errors.number3}</FormFeedback>
                        ) : null}

                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Uzun Açıklama</Label>

                        <Form method="post">
                          <CKEditor
                            editor={Editor}
                            data={getHtmlDataLong}
                            config={{
                              // @ts-ignore
                              extraPlugins: [uploadPlugin],
                            }}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataLong(data)

                            }}
                          />
                        </Form>
                      </div>


                      <div className="mb-3">
                        <Label className="form-label">KVKK Koşulları</Label>

                        <Form method="post">
                          <CKEditor
                            editor={Editor}
                            data={getHtmlDataKVKK1}
                            config={{
                              // @ts-ignore
                              extraPlugins: [uploadPlugin],
                            }}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataKvkk1(data)

                            }}
                          />
                        </Form>
                      </div>


                      <div className="mb-3">
                        <Label className="form-label">KVKK Koşulları 2</Label>

                        <Form method="post">
                          <CKEditor
                            editor={Editor}
                            data={getHtmlDataKVKK2}
                            config={{
                              // @ts-ignore
                              extraPlugins: [uploadPlugin],
                            }}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataKvkk2(data)

                            }}
                          />
                        </Form>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">KVKK Koşulları 3</Label>

                        <Form method="post">
                          <CKEditor
                            editor={Editor}
                            data={getHtmlDataKVKK3}
                            config={{
                              // @ts-ignore
                              extraPlugins: [uploadPlugin],
                            }}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataKvkk3(data)

                            }}
                          />
                        </Form>
                      </div>
                      <div className="mb-3">
                              <Col xs={12} className="mb-3">
                                <label htmlFor="resume">Logo</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

                              </Col>

                              <div>
                <img
                  className="avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' +  getFileName}
                  alt=""
                />
              </div>

                            </div>


                            <div className="mb-3">
                              <Col xs={12} className="mb-3">
                                <label htmlFor="resume">Video</label>
                                <Dropzone
                                  getUploadParams={getUploadParamsVideo}
                                  onChangeStatus={handleChangeStatusVideo}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="video/*"
                                  />

                              </Col>

                          

                            </div>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Kaydet
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
