import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Date, NameSurname } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import DateModal from "components/Common/DateModal";

import "jspdf-autotable";


import {
  getAvailable as onGetAvailable,
  addAvailable  as onAddAvailable,
  updateAvailable  as onUpdateAvailable,
  deleteAvailable  as onDeleteAvailable,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import { ExportToExcel2 } from "ExportToExcel2";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";


const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Kullanılan Kuponlar | KUPON ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();

  const [getFileSubMedia, setFileSubMedia] = useState([]);
  const [getFileSubMediaAdd, setFileSubMediaAdd] = useState([]);

  const [getSubMediaID, setSubMediaID] = useState();


  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.PortTitle) || "",
 
    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),


    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          PortTitle: values.title,
          AdvertID: getCategoryID,
         

        };
        dispatch(onUpdateAvailable(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          PortTitle: values["title"],
          AdvertID: getCategoryID,
    

        };
        // save new user
        dispatch(onAddAvailable(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.available,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);


  const [getCategoryID, setCategoryID] = useState(null);


  const [getCategory, setCategory] = useState([]);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Kampanya Adı",
        accessor: "Campain.Campain_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Kodlar",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>

       
            {  cellProps.Campain.Campain_History.map((item, index) => {
              return(
              <div key={index}>
            <span >
            {item.Campain_Code}
          </span>
          </div>)
            }) }
          </>
        ),
      },

      {
        Header: "Kullanan Kullanıcılar",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>

       
            {  cellProps.Campain.Campain_History.map((item, index) => {
              return(
              <div key={index}>
            <span >
            {item.Users.NameSurname}
          </span>
          </div>)
            }) }
          </>
        ),
      },
/** 
      {
        Header: "Yararlanabilen Kullanıcılar",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>

       
            {  cellProps.Company != null ? cellProps.Company.Users.map((item, index) => {
              return(
              <div key={index}>
            <span >
            {item.NameSurname}
          </span>
          </div>)
            }) : null }
          </>
        ),
      },*/




      {
        Header: "Firma Adı",
        accessor: "Company.Company_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Fiyat",
        accessor: "Price",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },


      {
        Header: "Komisyon Yüzdesi",
        accessor: "Campain.Discount",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Komisyon Tutarı",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>

<a>
{(parseInt(cellProps.Price) * parseInt(cellProps.Campain.Discount)) / 100}
</a>
       
         
          </>
        ),
      },


      {
        Header: "Oluşturulma Tarihi",
        accessor: "Created_DateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },


    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getAdvertTekneport/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    _getAuthData()
    
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };



  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

    setCategoryID(user.AdvertID)

  


    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteAvailable(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }



  const [getSelectDate, setSelectDate] = useState(null);
  const [getSelectDateFinish, setSelectDateFinish] = useState(null);
  const [getExcelModal, setExcelModal] = useState(false);

  
  const onFilterClickGet = () => {
      dispatch(onGetAvailable(getSelectDate,getSelectDateFinish));

   
    setExcelModal(false);
  }

  const exportToCSV = () => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    setExcelModal(false);

    let fileName = getSelectDate + " " + getSelectDateFinish;
    let getSelectDateDaysFinish = moment(getSelectDateFinish).add(1, 'days').format('YYYY/MM/DD');

    // Veri yapısını oluştur
    let campainCompanyUsage = {};

    // Her kampanyanın her şirket tarafından kaç kez kullanıldığını hesapla
    users.filter(x => x.Created_DateTime > getSelectDate && x.Created_DateTime < getSelectDateDaysFinish).forEach(user => {
        if (user.Campain) {
            const campainID = user.Campain.ID;
            const companyID = user.Company.ID;
            if (!campainCompanyUsage[campainID]) {
                campainCompanyUsage[campainID] = {};
            }
            if (!campainCompanyUsage[campainID][companyID]) {
                campainCompanyUsage[campainID][companyID] = 1;
            } else {
                campainCompanyUsage[campainID][companyID]++;
            }
        }
    });

    // Excel için veri hazırla
    let excelData = [];
    for (const campainID in campainCompanyUsage) {
        for (const companyID in campainCompanyUsage[campainID]) {
            const usageCount = campainCompanyUsage[campainID][companyID];
            const campainTitle = users.find(user => user.Campain.ID === parseInt(campainID)).Campain.Campain_Title;
            const companyName = users.find(user => user.Company.ID === parseInt(companyID)).Company.Company_Title;
            const Price = users.find(user => user.Company.ID === parseInt(companyID)).Price;
            const Commission = users.find(user => user.Company.ID === parseInt(companyID)).Campain.Discount;
            const Commission2 = (users.find(user => user.Company.ID === parseInt(companyID)).Price * users.find(user => user.Company.ID === parseInt(companyID)).Campain.Discount) / 100
            const Date = users.find(user => user.Company.ID === parseInt(companyID)).Created_DateTime;

            const companyHistory = users
            .filter(user => user.Company.ID === parseInt(companyID))
            .map(user => user.Campain.Campain_History)
            .flat();
          

            excelData.push({ "Kaç Kere Kullanıldığı": usageCount, "Firma Adı": companyName, "Kampanya Başlık": campainTitle ,"Kodlar": companyHistory.map(item => item.Campain_Code).join(", "),"Fiyat": Price ,"Komisyon Yüzdesi": Commission,"Komisyon Tutarı": Commission2,"Oluşturulma Tarihi": moment(Date).format('DD/MM/YYYY') });
        }
    }

    // Excel dosyasını oluştur
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    
    // Kullanıcıya dosyayı indirme seçeneği sun
    FileSaver.saveAs(data, fileName + fileExtension);
}



const handleChangeTextFinish = (text) => {

  setSelectDateFinish(text.target.value)

}
    const handleChangeText = (text) => {

    setSelectDate(text.target.value)

  }

  const data2 =  users.map(elt=> [
    elt.Campain.Campain_Title,
    ]);


  const onClickExcel = (order) => {
    setExcelModal(true);
  }

  return (
    <React.Fragment>

<DateModal
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}
        onFilterClick={exportToCSV}
        show={getExcelModal}
        onFilterClickGet={onFilterClickGet}
        onCloseClick={() => setExcelModal(false)}
      />
      

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Kullanılan Kuponlar Listesi" />
          <ExportToExcel2 title={"Tarih"} onDownloadClick={exportToCSV} onClickExcel={onClickExcel} apiData={data2} fileName={"fileName"} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

          

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Liman Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>



                            <div className="mb-3">
                              <Label className="form-label">Uygun Liman Adı</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Liman adı Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">İlan Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>


                     
                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
