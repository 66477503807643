import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_SUCCESS1,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_REFERANCES_SUCCESS,
  GET_WORKSHOP2_SUCCESS,
  GET_POPUP_SUCCESS,
  UPDATE_POPUP_SUCCESS,
  UPDATE_SUBDOMAIN_SUCCESS,
  UPDATE_SUCCESS_COURSESCHEDULES,
  UPDATE_WORKSHOPSESSION_SUCCESS,
  UPDATE_SUCCESS_TEACHER,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_ABOUT_SUCCESS,
  UPDATE_SUCCESS_NEWS,
  UPDATE_SUCCESS_MEGA,
  UPDATE_WORKSHOP2_SUCCESS,
  UPDATE_SUCCESS_GALLERY,

  GET_WORKSHOP_SUCCESS,
  GET_WORKSHOPSESSION_SUCCESS,
  GET_APPLICATION_SUCCESS,
  GET_SLIDER_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_NEWS_SUCCESS,
  GET_MEGA_SUCCESS,
  GET_TEACHER_SUCCESS,
  GET_DYNAMIC_SUCCESS,
  GET_CONTACT,
  GET_ABOUT,
  GET_AUTH_SUCCESS,
  GET_AUTHUPDATE_SUCCESS,
  UPDATE_SUCCESS_PROJECT,
  GET_GALLERY_SUCCESS,
  ADD_REFERANCES_SUCCESS,
  UPDATE_REFERANCES_SUCCESS,
  GET_SUBPROJECT_SUCCESS,
  GET_TEACHERWORKSHOP_SUCCESS,
  ADD_LESSONSTIME_SUCCESS,
  UPDATE_LESSONSTIME_SUCCESS,
  GET_CLASSATTENDANCE_SUCCESS,

  GET_CLASSES_SUCCESS,
  UPDATE_DYNAMICFORM_SUCCESS,
  ADD_DYNAMICFORM_SUCCESS,
  GET_DYNAMICFORMEXTRA_SUCCESS,
  GET_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  ADD_MEGA_SUCCESS,
  DELETE_MEGA_SUCCESS,
  DELETE_NEWS_SUCCESS,
  DELETE_SLIDER_SUCCESS,
  DELETE_POPUP_SUCCESS,
  DELETE_REFERANCES_SUCCESS,
  ADD_GALLERY_SUCCESS,
  DELETE_PRODUCTCATEGORY_SUCCESS,
  DELETE_GALLERY_SUCCESS,
  DELETE_COURSESCHEDULES_SUCCESS,
  DELETE_DYNAMICFORMEXTRA_SUCCESS,
  DELETE_DYNAMICFORM_SUCCESS,

  DELETE_RESERVATION_SUCCESS,
  GET_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_SUCCESS,
  ADD_RESERVATION_SUCCESS,

  DELETE_CAMPAIN_HISTORY_SUCCESS,
  GET_CAMPAIN_HISTORY_SUCCESS,
  UPDATE_CAMPAIN_HISTORY_SUCCESS,
  ADD_CAMPAIN_HISTORY_SUCCESS,

  DELETE_CAMPAINPERIOD_SUCCESS,
  GET_CAMPAINPERIOD_SUCCESS,
  UPDATE_CAMPAINPERIOD_SUCCESS,
  ADD_CAMPAINPERIOD_SUCCESS,

  DELETE_CAMPAINCATEGORY_SUCCESS,
  GET_CAMPAINCATEGORY_SUCCESS,
  UPDATE_CAMPAINCATEGORY_SUCCESS,
  ADD_CAMPAINCATEGORY_SUCCESS,


  DELETE_CAMPAIN_SUCCESS,
  GET_CAMPAIN_SUCCESS,
  UPDATE_CAMPAIN_SUCCESS,
  ADD_CAMPAIN_SUCCESS,


  DELETE_SUBSECTOR_SUCCESS,
  GET_SUBSECTOR_SUCCESS,
  UPDATE_SUBSECTOR_SUCCESS,
  ADD_SUBSECTOR_SUCCESS,


  DELETE_SECTOR_SUCCESS,
  GET_SECTOR_SUCCESS,
  UPDATE_SECTOR_SUCCESS,
  ADD_SECTOR_SUCCESS,

  DELETE_JOB_SUCCESS,
  GET_JOB_SUCCESS,
  UPDATE_JOB_SUCCESS,
  ADD_JOB_SUCCESS,

  DELETE_COMPANY_SUCCESS,
  GET_COMPANY_SUCCESS,
  UPDATE_COMPANY_SUCCESS,
  ADD_COMPANY_SUCCESS,


  DELETE_FEEDBACK_SUCCESS,
  GET_FEEDBACK_SUCCESS,
  UPDATE_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_SUCCESS,

  DELETE_BLOGCAT_SUCCESS,
  GET_BLOGCAT_SUCCESS,
  UPDATE_BLOGCAT_SUCCESS,
  ADD_BLOGCAT_SUCCESS,

  DELETE_BLOGSUBCAT_SUCCESS,
  GET_BLOGSUBCAT_SUCCESS,
  UPDATE_BLOGSUBCAT_SUCCESS,
  ADD_BLOGSUBCAT_SUCCESS,


  DELETE_AVAILABLE_SUCCESS,
  GET_AVAILABLE_SUCCESS,
  UPDATE_AVAILABLE_SUCCESS,
  ADD_AVAILABLE_SUCCESS,

  DELETE_OFFER_SUCCESS,
  GET_OFFER_SUCCESS,
  UPDATE_OFFER_SUCCESS,
  ADD_OFFER_SUCCESS,

  DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  GET_EDUCATION_SUCCESS,
  GET_SUBEDUCATION_SUCCESS,
  UPDATE_EDUCATION_SUCCESS,
  UPDATE_PRODUCTCATEGORY_SUCCESS,
  ADD_EDUCATION_SUCCESS,
  ADD_SUBEDUCATION_SUCCESS,
  ADD_SLIDER_SUCCESS,
  DELETE_SUBEDUCATION_SUCCESS,
  UPDATE_SUBEDUCATION_SUCCESS,
  UPDATE_SLIDER_SUCCESS,
  UPDATE_BLOG_SUCCESS,
  GET_PRODUCTCATEGORY_SUCCESS,
  GET_PRODUCT_SUCCESS,
  GET_SLİDERS_SUCCESS,
  GET_BLOGS_SUCCESS,
  ADD_BLOG_SUCCESS,
  ADD_PRODUCTCATEGORY_SUCCESS,
  DELETE_BLOG_SUCCESS,
  ADD_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  GET_ABOUT_SUCCESS,
  ADD_SSS_SUCCESS,
  DELETE_SSS_SUCCESS,
  UPDATE_SSS_SUCCESS,
  GET_SSS_SUCCESS,
  ADD_ADVERT_SUCCESS,
  DELETE_ADVERT_SUCCESS,
  UPDATE_ADVERT_SUCCESS,
  GET_ADVERT_SUCCESS,
  GET_CAMPAIN_HISTORYCOMPANY_SUCCESS,
  DELETE_CAMPAINCOMPANY_HISTORY_SUCCESS,
  ADD_CAMPAINCOMPANY_HISTORY_SUCCESS,
  UPDATE_CAMPAINCOMPANY_HISTORY_SUCCESS,
  ADD_NEW_USER_SUCCESS,
  DELETE_AUTH_SUCCESS,
  GET_FEEDBACKCATEGORY_SUCCESS,
  UPDATE_FEEDBACKCATEGORY_SUCCESS,
  ADD_FEEDBACKCATEGORY_SUCCESS,
  DELETE_FEEDBACKCATEGORY_SUCCESS,
  ADD_ABOUT2_SUCCESS,
  DELETE_ABOUT2_SUCCESS,
  UPDATE_ABOUT2,

} from "./actionTypes"

const INIT_STATE = {
  forum: [],
  users: [],
  userProfile: {},
  error: {},
  popup: [],
  workshop: [],
  workshopSession: [],
  workshopsessionMega: [],
  slider: [],
  projects: [],
  news: [],
  mega: [],
  teacher: [],
  dynamic: [],
  contact: [],
  authlist: [],
  workshop2: [],
  gallery: [],
  referances: [],
  subProject: [],
  lessonstime: [],
  classattendance: [],
  classes: [],

  dynamicformextra: [],
  dynamicformextraextra: [],
  education: [],
  educationSubItem: [],

  productCategory: [],
  products: [],
  sliders: [],
  blogs: [],
  about: [],
  sss: [],
  reservation: [],
  offer: [],
  available: [],
  advert: [],
  blogcat: [],
  blogsubcat: [],
  feedback:[],
  campaincat: [],
  campain: [],
  company:[],
  job:[],
  sector:[],
  subsector:[],
  campainperiod:[],
  campainhistory:[],
  campainhistoryCompany:[],
  campainDefault:[],
  feedbackCategory:[]
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {


    case DELETE_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: state.feedback.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }


    case UPDATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: state.feedback.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case ADD_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: [...state.feedback, action.payload],
      }


    case GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: action.payload,
      }





      case DELETE_CAMPAIN_HISTORY_SUCCESS:
        return {
          ...state,
          campainhistory: state.campainhistory.filter(
            user => user.ID.toString() !== action.payload.toString()
          ),
        }
  
  
      case UPDATE_CAMPAIN_HISTORY_SUCCESS:
        return {
          ...state,
          campainhistory: state.campainhistory.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }
  
      case ADD_CAMPAIN_HISTORY_SUCCESS:
        return {
          ...state,
          campainhistory: [...state.campainhistory, action.payload],
        }
  
  
      case GET_CAMPAIN_HISTORY_SUCCESS:
        return {
          ...state,
          campainhistory: action.payload,
        }




      case DELETE_SECTOR_SUCCESS:
        return {
          ...state,
          sector: state.sector.filter(
            user => user.ID.toString() !== action.payload.toString()
          ),
        }
  
  
      case UPDATE_SECTOR_SUCCESS:
        return {
          ...state,
          sector: state.sector.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }
  
      case ADD_SECTOR_SUCCESS:
        return {
          ...state,
          sector: [...state.sector, action.payload],
        }
  
  
      case GET_SECTOR_SUCCESS:
        return {
          ...state,
          sector: action.payload,
        }
  



      case DELETE_CAMPAINPERIOD_SUCCESS:
        return {
          ...state,
          campainperiod: state.campainperiod.filter(
            user => user.ID.toString() !== action.payload.toString()
          ),
        }
  
  
      case UPDATE_CAMPAINPERIOD_SUCCESS:
        return {
          ...state,
          campainperiod: state.campainperiod.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }
  
      case ADD_CAMPAINPERIOD_SUCCESS:
        return {
          ...state,
          campainperiod: [...state.campainperiod, action.payload],
        }
  
  
      case GET_CAMPAINPERIOD_SUCCESS:
        return {
          ...state,
          campainperiod: action.payload,
        }
  

        case GET_CAMPAIN_HISTORYCOMPANY_SUCCESS:
          return {
            ...state,
            campainhistoryCompany: action.payload,
          }

          
          case ADD_CAMPAINCOMPANY_HISTORY_SUCCESS:
            return {
              ...state,
              campainhistoryCompany: [...state.campainhistoryCompany, action.payload],
            }

          case DELETE_CAMPAINCOMPANY_HISTORY_SUCCESS:
            return console.log("asdasd",action)

            case UPDATE_CAMPAINCOMPANY_HISTORY_SUCCESS:
              return {
                ...state,
                campainhistoryCompany: state.campainhistoryCompany.map(x =>
                  x.ID.toString() === action.payload.ID.toString()
                    ? { x, ...action.payload }
                    : x
                ),
              }
            
        

        case DELETE_SUBSECTOR_SUCCESS:
          return {
            ...state,
            subsector: state.subsector.filter(
              user => user.ID.toString() !== action.payload.toString()
            ),
          }
    
    
        case UPDATE_SUBSECTOR_SUCCESS:
          return {
            ...state,
            subsector: state.subsector.map(x =>
              x.ID.toString() === action.payload.ID.toString()
                ? { x, ...action.payload }
                : x
            ),
          }
    
        case ADD_SUBSECTOR_SUCCESS:
          return {
            ...state,
            subsector: [...state.subsector, action.payload],
          }
    
    
        case GET_SUBSECTOR_SUCCESS:
          return {
            ...state,
            subsector: action.payload,
          }


    case DELETE_JOB_SUCCESS:
      return {
        ...state,
        job: state.job.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }


    case UPDATE_JOB_SUCCESS:
      return {
        ...state,
        job: state.job.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case ADD_JOB_SUCCESS:
      return {
        ...state,
        job: [...state.job, action.payload],
      }


    case GET_JOB_SUCCESS:
      return {
        ...state,
        job: action.payload,
      }









      case DELETE_CAMPAIN_SUCCESS:
        return {
          ...state,
          campain: state.campain.filter(
            user => user.ID.toString() !== action.payload.toString()
          ),
        }
  
  
      case UPDATE_CAMPAIN_SUCCESS:
        return {
          ...state,
          campain: state.campain.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }
  
        
        
        case ADD_NEW_USER_SUCCESS:
          return {
            ...state,
            authlist: [...state.authlist, action.payload],
          }

          
          case DELETE_FEEDBACKCATEGORY_SUCCESS:
            return {
              ...state,
              feedbackCategory: state.feedbackCategory.filter(
                user => user.ID.toString() !== action.payload.toString()
              ),
            }

      case ADD_CAMPAIN_SUCCESS:
        return {
          ...state,
          campainDefault : action.payload,
          campain: [...state.campain, action.payload],
        }
  
        case ADD_FEEDBACKCATEGORY_SUCCESS:
        return {
          ...state,
          feedbackCategory: [...state.feedbackCategory, action.payload],
        }

        case UPDATE_FEEDBACKCATEGORY_SUCCESS:
          return {
            ...state,
            feedbackCategory: state.feedbackCategory.map(x =>
              x.ID.toString() === action.payload.ID.toString()
                ? { x, ...action.payload }
                : x
            ),
          }

        case GET_FEEDBACKCATEGORY_SUCCESS:
        return {
          ...state,
          feedbackCategory: action.payload,
        }

      case GET_CAMPAIN_SUCCESS:
        return {
          ...state,
          campain: action.payload,
        }

        case DELETE_AUTH_SUCCESS:
          return {
            ...state,
            authlist: state.authlist.filter(
              user => user.ID.toString() !== action.payload.toString()
            ),
          }
    


        case DELETE_COMPANY_SUCCESS:
          return {
            ...state,
            company: state.company.filter(
              user => user.ID.toString() !== action.payload.toString()
            ),
          }
    
    
        case UPDATE_COMPANY_SUCCESS:
          return {
            ...state,
            company: state.company.map(x =>
              x.ID.toString() === action.payload.ID.toString()
                ? { x, ...action.payload }
                : x
            ),
          }
    
        case ADD_COMPANY_SUCCESS:
          return {
            ...state,
            company: [...state.company, action.payload],
          }
    
    
        case GET_COMPANY_SUCCESS:
          return {
            ...state,
            company: action.payload,
          }
    

      case DELETE_CAMPAINCATEGORY_SUCCESS:
        return {
          ...state,
          campaincat: state.campaincat.filter(
            user => user.ID.toString() !== action.payload.toString()
          ),
        }
  
  
      case UPDATE_CAMPAINCATEGORY_SUCCESS:
        return {
          ...state,
          campaincat: state.campaincat.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }
  
      case ADD_CAMPAINCATEGORY_SUCCESS:
        return {
          ...state,
          campaincat: [...state.campaincat, action.payload],
        }
  
  
      case GET_CAMPAINCATEGORY_SUCCESS:
        return {
          ...state,
          campaincat: action.payload,
        }
  







    case DELETE_BLOGCAT_SUCCESS:
      return {
        ...state,
        blogcat: state.blogcat.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }


    case UPDATE_BLOGCAT_SUCCESS:
      return {
        ...state,
        blogcat: state.blogcat.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case ADD_BLOGCAT_SUCCESS:
      return {
        ...state,
        blogcat: [...state.blogcat, action.payload],
      }


    case GET_BLOGCAT_SUCCESS:
      return {
        ...state,
        blogcat: action.payload,
      }




      case DELETE_BLOGSUBCAT_SUCCESS:
        return {
          ...state,
          blogsubcat: state.blogsubcat.filter(
            user => user.ID.toString() !== action.ID.toString()
          ),
        }
  
  
      case UPDATE_BLOGSUBCAT_SUCCESS:
        return {
          ...state,
          blogsubcat: state.blogsubcat.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }
  
      case ADD_BLOGSUBCAT_SUCCESS:
        return {
          ...state,
          blogsubcat: [...state.blogsubcat, action.payload],
        }
  
  
      case GET_BLOGSUBCAT_SUCCESS:
        return {
          ...state,
          blogsubcat: action.payload,
        }
  





    case DELETE_ADVERT_SUCCESS:
      return {
        ...state,
        advert: state.advert.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }


    case UPDATE_ADVERT_SUCCESS:
      return {
        ...state,
        advert: state.advert.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case ADD_ADVERT_SUCCESS:
      return {
        ...state,
        advert: [...state.advert, action.payload],
      }


    case GET_ADVERT_SUCCESS:
      return {
        ...state,
        advert: action.payload,
      }



    case DELETE_OFFER_SUCCESS:
      return {
        ...state,
        offer: state.offer.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }


    case UPDATE_OFFER_SUCCESS:
      return {
        ...state,
        offer: state.offer.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case ADD_OFFER_SUCCESS:
      return {
        ...state,
        offer: [...state.offer, action.payload],
      }


    case GET_OFFER_SUCCESS:
      return {
        ...state,
        offer: action.payload,
      }



      case DELETE_AVAILABLE_SUCCESS:
        return {
          ...state,
          available: state.available.filter(
            user => user.ID.toString() !== action.ID.toString()
          ),
        }
  
  
      case UPDATE_AVAILABLE_SUCCESS:
        return {
          ...state,
          available: state.available.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }
  
      case ADD_AVAILABLE_SUCCESS:
        return {
          ...state,
          available: [...state.available, action.payload],
        }
  
  
      case GET_AVAILABLE_SUCCESS:
        return {
          ...state,
          available: action.payload,
        }



    case DELETE_RESERVATION_SUCCESS:
      return {
        ...state,
        reservation: state.reservation.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }


    case UPDATE_RESERVATION_SUCCESS:
      return {
        ...state,
        reservation: state.reservation.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case ADD_RESERVATION_SUCCESS:
      return {
        ...state,
        reservation: [...state.reservation, action.payload],
      }


    case GET_RESERVATION_SUCCESS:
      return {
        ...state,
        reservation: action.payload,
      }




    case DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextraextra: state.dynamicformextraextra.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_DYNAMICFORM_SUCCESS:
      return {
        ...state,
        dynamic: state.dynamic.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }

    case DELETE_DYNAMICFORMEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextra: state.dynamicformextra.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }


    case UPDATE_EDUCATION_SUCCESS:
      return {
        ...state,
        education: state.education.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_PRODUCTCATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: state.productCategory.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }


    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }


    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }


    case DELETE_BLOG_SUCCESS:
      return {
        ...state,
        blogs: state.blogs.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }


    case UPDATE_BLOG_SUCCESS:
      return {
        ...state,
        blogs: state.blogs.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }
    case UPDATE_ABOUT_SUCCESS:
      return {
        ...state,
        about: state.about.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }



    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }

    case DELETE_COURSESCHEDULES_SUCCESS:
      return {
        ...state,
        workshopSession: state.workshopSession.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: state.gallery.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_PRODUCTCATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: state.productCategory.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }
    case UPDATE_SUBEDUCATION_SUCCESS:
      return {
        ...state,
        educationSubItem: state.dynamic.map(x =>
          x.ID == action.educationSubItem.ID
            ? { x, ...action.payload }
            : x
        ),
      }



    case DELETE_SUBEDUCATION_SUCCESS:
      return {
        ...state,
        educationSubItem: state.educationSubItem.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }

    case ADD_SUBEDUCATION_SUCCESS:
      return {
        ...state,
        educationSubItem: [...state.educationSubItem, action.payload],
      }


    case ADD_SLIDER_SUCCESS:
      return {
        ...state,
        sliders: [...state.sliders, action.payload],
      }

    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload],
      }


    case ADD_EDUCATION_SUCCESS:
      return {
        ...state,
        education: [...state.education, action.payload],
      }

    case ADD_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: [...state.gallery, action.payload],
      }

    case DELETE_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: state.referances.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_POPUP_SUCCESS:
      return {
        ...state,
        popup: state.popup.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_SLIDER_SUCCESS:
      return {
        ...state,
        sliders: state.sliders.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_SSS_SUCCESS:
      return {
        ...state,
        sss: state.sss.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }

    case DELETE_NEWS_SUCCESS:
      return {
        ...state,
        news: state.news.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_MEGA_SUCCESS:
      return {
        ...state,
        mega: state.mega.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }


    case ADD_MEGA_SUCCESS:
      return {
        ...state,
        mega: [...state.mega, action.payload],
      }



    case GET_ABOUT_SUCCESS:
      return {
        ...state,
        about: action.payload,
      }
    case GET_SSS_SUCCESS:
      return {
        ...state,
        sss: action.payload,
      }


    case GET_SUBEDUCATION_SUCCESS:
      return {
        ...state,
        educationSubItem: action.payload,
      }

    case GET_PRODUCTCATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: action.payload,
      }


    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.payload,
      }


    case GET_SLİDERS_SUCCESS:
      return {
        ...state,
        sliders: action.payload,
      }


    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: action.payload,
      }
    case GET_EDUCATION_SUCCESS:
      return {
        ...state,
        education: action.payload,
      }

    case GET_DYNAMICFORMEXTRAEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextraextra: action.payload,
      }

    case GET_DYNAMICFORMEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextra: action.payload,
      }

    case UPDATE_DYNAMICFORM_SUCCESS:
      return {
        ...state,
        dynamic: state.dynamic.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case GET_CLASSES_SUCCESS:
      return {
        ...state,
        classes: action.payload,
      }

    case GET_CLASSATTENDANCE_SUCCESS:
      return {
        ...state,
        classattendance: action.payload,
      }

    case UPDATE_LESSONSTIME_SUCCESS:
      return {
        ...state,
        lessonstime: state.lessonstime.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case ADD_DYNAMICFORM_SUCCESS:
      return {
        ...state,
        dynamic: [...state.dynamic, action.payload],
      }

    case ADD_SSS_SUCCESS:
      return {
        ...state,
        sss: [...state.sss, action.payload],
      }
    case ADD_LESSONSTIME_SUCCESS:
      return {
        ...state,
        lessonstime: [...state.lessonstime, action.payload],
      }

    case GET_TEACHERWORKSHOP_SUCCESS:
      return {
        ...state,
        lessonstime: action.payload,
      }


    case GET_SUBPROJECT_SUCCESS:
      return {
        ...state,
        subProject: action.payload,
      }

    case UPDATE_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: state.referances.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_SSS_SUCCESS:
      return {
        ...state,
        sss: state.sss.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
    case GET_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: action.payload,
      }

    case GET_AUTHUPDATE_SUCCESS:
      return {
        ...state,
        authlist: [],
      }

    case GET_AUTH_SUCCESS:
      return {
        ...state,
        authlist: action.payload,
      }


    case GET_WORKSHOP2_SUCCESS:
      return {
        ...state,
        workshop2: action.payload,
      }

    case GET_CONTACT:
      return {
        ...state,
        contact: action.payload,
      }



    case GET_DYNAMIC_SUCCESS:
      return {
        ...state,
        dynamic: action.payload,
      }

    
      case ADD_ABOUT2_SUCCESS:
        return {
          ...state,
          teacher: [...state.teacher, action.payload],
        }

      
        case UPDATE_ABOUT2:
          console.log("deneme123", action.payload)
          return {
            ...state,
            teacher: state.teacher.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }

          
      case DELETE_ABOUT2_SUCCESS:
          return {
            ...state,
            teacher: state.teacher.filter(
              user => user.payload.toString() !== action.payload.toString()
            ),
          }
    
        
    case GET_TEACHER_SUCCESS:
      return {
        ...state,
        teacher: action.payload,
      }
    case GET_MEGA_SUCCESS:
      return {
        ...state,
        mega: action.payload,
      }

    case GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload,
      }

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      }

    case GET_SLIDER_SUCCESS:
      return {
        ...state,
        slider: action.payload,
      }
    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        workshopsessionMega: action.payload,
      }
    case GET_WORKSHOPSESSION_SUCCESS:
      return {
        ...state,
        workshopSession: action.payload,
      }
    case GET_WORKSHOP_SUCCESS:
      return {
        ...state,
        workshop: action.payload,
      }

    case GET_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: action.payload,
      }


    case UPDATE_SUCCESS_GALLERY:
      console.log("deneme123", action.payload)
      return {
        ...state,
        gallery: state.gallery.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }


    case UPDATE_SUCCESS_MEGA:
      console.log("deneme123", action.payload)
      return {
        ...state,
        mega: state.mega.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }



    case UPDATE_WORKSHOP2_SUCCESS:
      return {
        ...state,
        workshop2: state.workshop2.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_SUCCESS_NEWS:
      return {
        ...state,
        news: state.news.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contact: state.contact.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_SUCCESS_PROJECT:
      return {
        ...state,
        projects: state.projects.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_SLIDER_SUCCESS:
      return {
        ...state,
        sliders: state.sliders.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_SUBDOMAIN_SUCCESS:
      return {
        ...state,
        workshop: state.workshop.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }


    case UPDATE_SUCCESS_TEACHER:
      return {
        ...state,
        teacher: state.teacher.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }


    case UPDATE_WORKSHOPSESSION_SUCCESS:
      return {
        ...state,
        workshopsessionMega: state.workshopsessionMega.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_SUCCESS_COURSESCHEDULES:
      console.log("lksdnfsd", state.workshopSession)
      return {
        ...state,
        workshopSession: state.workshopSession.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case UPDATE_POPUP_SUCCESS:
      return {
        ...state,
        popup: state.popup.map(x =>
          x.ID.toString() === action.payload.ID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }

    case GET_POPUP_SUCCESS:
      return {
        ...state,
        popup: action.payload,
      }

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case ADD_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: [...state.referances, action.payload],
      }

    case ADD_PRODUCTCATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: [...state.productCategory, action.payload],
      }

    case ADD_BLOG_SUCCESS:
      return {
        ...state,
        blogs: [...state.blogs, action.payload],
      }
    case ADD_USER_SUCCESS1:
      return {
        ...state,
        users: [...state.users, action.payload],
      }


    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      }



    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default contacts
