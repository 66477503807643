import axios from "axios";
import { del, get, post, put, patch } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// get Events

// add Events

// update Event


// delete Event
export const deleteEvent = project =>
  del(`https://kuponadmin.stechomeyazilim.info:9595/deleteEventMega/${project}`);


// get Categories

// get chats

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages


// post messages
export const getselectedmails = (selectedmails) => post(url.GET_SELECTED_MAILS, selectedmails);

//post setfolderonmails
export const setfolderonmails = (selectedmails, folderId, activeTab) => post(url.SET_FOLDER_SELECTED_MAILS, { selectedmails, folderId, activeTab });

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer);

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer);

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } });

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get crypto product
export const getCryptoProduct = () => get(url.GET_CRYPTO_PRODUCTS);

// get invoices
export const getInvoices = () => get(url.GET_INVOICES);

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get jobs
export const getJobList = () => get(url.GET_JOB_LIST);

// get Apply Jobs
export const getApplyJob = () => get(url.GET_APPLY_JOB);

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// get tasks
export const getTasks = () => get(url.GET_TASKS);

export const addSubEducation = user => post(`https://kuponadmin.stechomeyazilim.info:9595/addCampainHistoryCompany/send`, user);
export const updateSubEducation = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateSubEducationNefes/${user.ID}`, user);
export const deleteSubEducation = user => del(`https://kuponadmin.stechomeyazilim.info:9595/deleteSubEducation/${user}`);
export const addEducation = user => post(`https://kuponadmin.stechomeyazilim.info:9595/addEducationNefes/send`, user);
export const addProductCategory = user => post(`https://kuponadmin.stechomeyazilim.info:9595/addProductsCategoryLuxun/send`, user);
export const deleteProductCategory = user => del(`https://kuponadmin.stechomeyazilim.info:9595/deleteProductCategoryLuxun/${user}`);
export const updateProductCategory = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateProductCategoryLuxun/${user.ID}`, user);
export const updateEducation = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateEducationNefes/${user.ID}`, user);

export const updateContact = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateContactPort/${user.ID}`, user);
export const getCategory = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getProductsCategoryLuxun/select/`);


export const getAvailable = (sd,fd) => get(`https://kuponadmin.stechomeyazilim.info:9595/getCampainHistoryDate/select/${sd}/${fd}`);
export const updateAvailable = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateAvailablePortsPort/${user.ID}`, user);
export const addAvailable = job => post(`https://kuponadmin.stechomeyazilim.info:9595/addAvailablePortsPort/send`, job);
export const deleteAvailable = project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteAvailablePortsPort/${project}`);



export const getCampainCategory = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getCampainCategoryKupon/select/`);
export const updateCampainCategory = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateCampainCategoryKupon/${user.ID}`, user);
export const addCampainCategory = job => post(`https://kuponadmin.stechomeyazilim.info:9595/addCampainCategoryKupon/send`, job);
export const deleteCampainCategory= project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteCampainCategoryKupon/${project}`);



export const getCampain = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getCampainKupon/select/`);
export const updateCampain= user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateCampainKupon/${user.ID}`, user);
export const addCampain = job => post(`https://kuponadmin.stechomeyazilim.info:9595/addCampainKupon/send`, job);

export const deleteCampain= project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteCampainKupon/${project}`);


export const getEducationSubItem = user => get(`https://kuponadmin.stechomeyazilim.info:9595/getCampainHistoryKuponFilter/select/${user}`);

export const getCampainHistory = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getCampain_HistoryKupon/select/`);
export const updateCampainHistory= user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateCampain_HistoryKupon/${user.ID}`, user);
export const addCampainHistory = job => post(`https://kuponadmin.stechomeyazilim.info:9595/addCampain_HistoryKupon/send`, job);

export const deleteCampainHistory= project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteCampain_HistoryKupon/${project}`);


export const getJob = () => get(`https://tusfadmin.stechomeyazilim.info:9098/getMediaTusf/select/`);
export const updateJob= user => patch(`https://tusfadmin.stechomeyazilim.info:9098/patchMediaTusf/${user.ID}`, user);
export const addJob = job => post(`https://tusfadmin.stechomeyazilim.info:9098/addMediaTusf/send`, job);
export const deleteJob= project =>
del(`https://tusfadmin.stechomeyazilim.info:9098/deleteMediaTusf/${project}`);



export const getCampainPeriod = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getCampaign_PeriodKupon/select/`);
export const updateCampainPeriod= user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateCampaign_PeriodKupon/${user.ID}`, user);
export const addCampainPeriod= job => post(`https://kuponadmin.stechomeyazilim.info:9595/Campaign_PeriodKupon/send`, job);
export const deleteCampainPeriod= project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/Campaign_PeriodKupon/${project}`);


export const getSubSector = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getSubSectorKupon/select/`);
export const updateSubSector= user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateSubSectorKupon/${user.ID}`, user);
export const addSubSector = job => post(`https://kuponadmin.stechomeyazilim.info:9595/addSubSectorKupon/send`, job);
export const deleteSubSector= project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteSubSectorKupon/${project}`);

export const getCompany = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getCompanyKupon/select/`);
export const updateCompany = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateCompanyKupon/${user.ID}`, user);
export const addCompany= job => post(`https://kuponadmin.stechomeyazilim.info:9595/addCompanyKupon/send`, job);
export const deleteCompany= project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteCompanyKupon/${project}`);


export const getProducts1 = () => get(`https://tusfadmin.stechomeyazilim.info:9098/getFormTusf/select/`);
export const addProduct = job => post(`https://tusfadmin.stechomeyazilim.info:9098/addFormTusf/send`, job);
export const deleteProduct = project =>
  del(`https://tusfadmin.stechomeyazilim.info:9098/deleteFormTusf/${project}`);
export const updateProduct = user => patch(`https://tusfadmin.stechomeyazilim.info:9098/patchFormTusf/${user.ID}`, user);


export const getFeedBack = () => get(`https://tusfadmin.stechomeyazilim.info:9098/getNewsTusf/select/`);
export const updateFeedBack = user => patch(`https://tusfadmin.stechomeyazilim.info:9098/patchNewsTusf/${user.ID}`, user);
export const addFeedBack= job => post(`https://tusfadmin.stechomeyazilim.info:9098/addNewsTusf/send`, job);
export const deleteFeedBack= project =>
del(`https://tusfadmin.stechomeyazilim.info:9098/deleteNewsTusf/${project}`);


export const getBlogCat = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getCampainCompany/select/`);
export const updateBlogCat = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateBlogCategoryMSE/${user.ID}`, user);
export const addBlogCat = job => post(`https://kuponadmin.stechomeyazilim.info:9595/addBlogCategoryMSE/send`, job);
export const deleteBlogCat= project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteBlogCategoryMSE/${project}`);


export const getBlogSubCat = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getSubCategoryMSE/select/`);
export const updateBlogSubCat = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateSubCategoryMSE/${user.ID}`, user);
export const addBlogSubCat = job => post(`https://kuponadmin.stechomeyazilim.info:9595/addSubCategoryMSE/send`, job);
export const deleteBlogSubCat= project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteBlogSubCategoryMSE/${project}`);


export const getFeedBackCategory = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getFeedBackCategoryKupon/select/`);

export const getCompanyReferanceCode = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getCompanyReferanceCode/select/`);
export const updateCompanyReferanceCode = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateCompanyReferanceCode/${user.ID}`, user);
export const updateFeedbackCategory = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateFeedbackCategory/${user.ID}`, user);
export const addFeedBackCategory= job => post(`https://kuponadmin.stechomeyazilim.info:9595/addFeedBackCategory/send`, job);
export const deleteFeedBackCategory= project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteFeedBackCategory/${project}`);

export const addCompanyReferanceCode= job => post(`https://kuponadmin.stechomeyazilim.info:9595/addCompanyReferanceCode/send`, job);
export const deleteCompanyReferanceCode= project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteCompanyReferanceCode/${project}`);

export const deleteAuth= project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteAuthKupon/${project}`);

export const getAdvert = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getAdvertTekneport/select/`);
export const updateAdvert = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateTekneportAdvert/${user.ID}`, user);
export const addAdvert= job => post(`https://kuponadmin.stechomeyazilim.info:9595/addAdvertTekneport/send`, job);
export const deleteAdvert= project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteAdvertPortsPort/${project}`);



export const getReservation = () => get(`https://tusfadmin.stechomeyazilim.info:9098/getNewsCommentTusf/select/`);
export const updateReservation = user => patch(`https://tusfadmin.stechomeyazilim.info:9098/patchNewsCommentTusf/${user.ID}`, user);
export const addReservation = job => post(`https://tusfadmin.stechomeyazilim.info:9098/addNewsCommentTusf/send`, job);
export const deleteReservation = project =>
del(`https://tusfadmin.stechomeyazilim.info:9098/deleteNewsCommentTusf/${project}`);


export const getOffer = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getOfferPort/select/`);
export const updateOffer = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateSliderPort/${user.ID}`, user);
export const addOffer = job => post(`https://kuponadmin.stechomeyazilim.info:9595/addSliderPort/send`, job);
export const deleteOffer = project =>
del(`https://kuponadmin.stechomeyazilim.info:9595/deleteSSSPort/${project}`);




export const getProjectsNew = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getEducationNefes/select/`);
export const getEducation = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getEducationNefes/select/`);

export const getPopup = user => get(`https://kuponadmin.stechomeyazilim.info:9595/getUserCompanyKupon/select/${user}`);

export const getWorkShop = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getSUBDOMAINMega/select/`);

export const getCourseSchedulesMega = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getCourseSchedulesMega/select/`);

export const getWorkshopSessionMega = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getWorkshopSessionMega/select/`);


export const getEventMega = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getEventMega/select/`);





export const getSlider = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getSliderKupon/select/`);


export const getNews = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getNewsMega/select/`);

export const getMegaMega = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getMegaMega/select/`);

export const getMailsLists = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getContactMessageMega/select/`);

// add user

const postFakeLogin = data => get(`https://tusfadmin.stechomeyazilim.info:9098/getUserLoginTusf/select/${data.email}/${data.password}`,);

export const getDynamicFormMega = () => get(`https://tusfadmin.stechomeyazilim.info:9098/getAbout2CategoryTusf/select/`);
export const addDynamicForm = job => post(`https://tusfadmin.stechomeyazilim.info:9098/addAbout2CategoryTusf/send`, job);
export const updateDynamicForm = user => patch(`https://tusfadmin.stechomeyazilim.info:9098/patchAbout2CategoryTusf/${user.ID}`, user);
export const deleteDynamicForm = project =>
  del(`https://tusfadmin.stechomeyazilim.info:9098/deleteAbout2CategoryTusf/${project}`);

export const getSector = () => get(`https://tusfadmin.stechomeyazilim.info:9098/getCounterTusf/select/`);

export const addSector = job => post(`https://tusfadmin.stechomeyazilim.info:9098/addCounterTusf/send`, job);
export const deleteSector= project =>
  del(`https://tusfadmin.stechomeyazilim.info:9098/deleteCounterTusf/${project}`);
export const updateSector= user => patch(`https://tusfadmin.stechomeyazilim.info:9098/patchCounterTusf/${user.ID}`, user);

export const getTeacherMega = () => get(`https://tusfadmin.stechomeyazilim.info:9098/getAbout2Tusf/select/`);
export const updateAbout2 = user => patch(`https://tusfadmin.stechomeyazilim.info:9098/patchAbout2Tusf/${user.ID}`, user);
export const updateTeacher = user => patch(`https://tusfadmin.stechomeyazilim.info:9098/patchAbout2Tusf/${user.ID}`, user);

export const addAbout2 = job => post(`https://tusfadmin.stechomeyazilim.info:9098/addAbout2Tusf/send`, job);
export const deleteAbout2 = project =>
  del(`https://tusfadmin.stechomeyazilim.info:9098/deleteAbout2Tusf/${project}`);
  

export const getEvents = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getEventMega/select/`);
export const getAbout = () => get(`https://tusfadmin.stechomeyazilim.info:9098/getAboutTusf/select/`);
export const updateAbout = user => patch(`https://tusfadmin.stechomeyazilim.info:9098/patchAboutTusf/${user.ID}`, user);

export const getSSS = () => get(`https://tusfadmin.stechomeyazilim.info:9098/getSSSTusf/select/`);
export const addSSS= job => post(`https://tusfadmin.stechomeyazilim.info:9098/addSSSTusf/send`, job);
export const updateSSS = user => patch(`https://tusfadmin.stechomeyazilim.info:9098/updateSSSTusf/${user.ID}`, user);

export const getBlogs = () => get(`https://tusfadmin.stechomeyazilim.info:9098/getBlogTusf/select/`);
export const updateBlog = user => patch(`https://tusfadmin.stechomeyazilim.info:9098/patchBlogTusf/${user.ID}`, user);
export const addBlog = user => post(`https://tusfadmin.stechomeyazilim.info:9098/addBlogTusf/send`, user);
export const deleteSSS = project =>
  del(`https://tusfadmin.stechomeyazilim.info:9098/deleteBlogTusf/${project}`);
  
export const deleteBlog = project =>
  del(`https://tusfadmin.stechomeyazilim.info:9098/deleteBlogTusf/${project}`);

export const deleteSlider = project =>
  del(`https://tusfadmin.stechomeyazilim.info:9098/deleteSliderTusf/${project}`);
export const addSlider = job => post(`https://tusfadmin.stechomeyazilim.info:9098/addSliderTusf/send`, job);
export const updateSlider = user => patch(`https://tusfadmin.stechomeyazilim.info:9098/patchSliderTusf/${user.ID}`, user);
export const getSliders = () => get(`https://tusfadmin.stechomeyazilim.info:9098/getSliderTusf/select/`);

export const getUsers = () => get(`https://tusfadmin.stechomeyazilim.info:9098/getUsersTusf/select/`);
export const deleteUser = user => del(`https://tusfadmin.stechomeyazilim.info:9098/deleteUsersTusf/${user}`);
export const updateUser = user => patch(`https://tusfadmin.stechomeyazilim.info:9098/patchUsersTusf/${user.ID}`, user);
export const addUser = job => post(`https://tusfadmin.stechomeyazilim.info:9098/addUsersTusf/send`, job);

export const getCategories = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getEventCategoryMega/select/`);
// update user

export const updateSubDomain = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/patchSUBDOMAINMega/${user.ID}`, user);

export const updateCourseSchedules = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/patchCourseSchedulesMega/${user.ID}`, user);

export const updateWorkshopSession = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/patchWorkShopSessionMega/${user.ID}`, user);





export const updatePopup = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateUsersKupon/${user.ID}`, user);

export const addPopup = job => post(`https://kuponadmin.stechomeyazilim.info:9595/addUsersKupon/send`, job);

export const deletePopup = project =>
  del(`https://kuponadmin.stechomeyazilim.info:9595/deleteUsersKupon/${project}`);


export const updateProject = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/patchUpdateProject/${user.ID}`, user);

export const updateNews = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/patchNews/${user.ID}`, user);



export const addNewUser = user => post(`https://kuponadmin.stechomeyazilim.info:9595/addGroupTypeKupon/send`, user);


export const addProject = job => post(`https://kuponadmin.stechomeyazilim.info:9595/addProject/send`, job);

export const addNews = job => post(`https://kuponadmin.stechomeyazilim.info:9595/addNews/send`, job);

export const addMega = job => post(`https://kuponadmin.stechomeyazilim.info:9595/addMega/send`, job);

export const addCourseMega = job => post(`https://kuponadmin.stechomeyazilim.info:9595/addCourseMega/send`, job);

export const updateSubdomain = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/patchSUBDOMAINMega/${user.ID}`, user);




export const updateMega = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/patchMegaMega/${user.ID}`, user);

export const updateGallery = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/patchGalleryMega/${user.ID}`, user);

export const updateEvent = event => patch(`https://kuponadmin.stechomeyazilim.info:9595/patchEventMega/${event.ID}`, event);

export const addAppSetting = job => post(`https://kuponadmin.stechomeyazilim.info:9595/postWSMega/send`, job);

export const addWorkshop2 = job => post(`https://kuponadmin.stechomeyazilim.info:9595/postWorkshopMega/send`, job);



// delete user

export const getContact = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getContactPort/select/`);
export const getWorkshop2 = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getWorkshopMega/select/`);

export const updateWorkShop2 = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/patchWorkshop2Mega/${user.ID}`, user);

export const getGallery = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getGalleryMega/select/`);

export const addNewEvent = event => post(`https://kuponadmin.stechomeyazilim.info:9595/postEventMega/send/`, event);

export const getChats = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getContactMessageMega/select/`);

export const getMessages = (roomId = "") => get(`https://kuponadmin.stechomeyazilim.info:9595/getContactMessageDetailMega/select/${roomId}`);

export const addNewSubdomain = event => post(`https://kuponadmin.stechomeyazilim.info:9595/postSubdomainMega/send/`, event);
export const getReferances = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getReferancesMega/select/`);
// add jobs
export const addNewJobList = job => post(url.ADD_NEW_JOB_LIST, job);
// update jobs
export const updateJobList = job => put(url.UPDATE_JOB_LIST, job);
// delete jobs
export const deleteJobList = job => del(url.DELETE_JOB_LIST, { headers: { job } });
// Delete Apply Jobs
export const deleteApplyJob = data => del(url.DELETE_APPLY_JOB, { headers: { data } });
/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);


export const addReferances = event => post(`https://kuponadmin.stechomeyazilim.info:9595/postReferancesMega/send/`, event);

export const updateReferances = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/patchReferancesMega/${user.ID}`, user);

export const getSubProject = (ID = "") => get(`https://kuponadmin.stechomeyazilim.info:9595/getProjectSubItem/select/${ID}`);

export const getTeacherWorkshop = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getTeacherWorkshopMega/select/`);

export const addLessonsTime = event => post(`https://kuponadmin.stechomeyazilim.info:9595/postLessonsTimeMega/send/`, event);

export const updateLessonsTime = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/patchTeacherWorkshopMega/${user.ID}`, user);

export const getClassAttendance = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getClassAttendanceMega/select/`);

export const getClassesMega = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getClassesMega/select/`);


export const getDynamicFormExtraMega = (ID = "") => get(`https://kuponadmin.stechomeyazilim.info:9595/getDynamicFormExtraMega/select/${ID}`);

export const getDynamicFormExtraExtraMega = (ID = "") => get(`https://kuponadmin.stechomeyazilim.info:9595/getDynamicFormExtraExtraMega/select/${ID}`);


export const addGallerry = job => post(`https://kuponadmin.stechomeyazilim.info:9595/postGalleryMega/send`, job);

export const addMultipleGalleryForm = job => post(`https://kuponadmin.stechomeyazilim.info:9595/postGalleryMultipleMega/send`, job);


export const deleteDynamicFormExtraExtra = project =>
  del(`https://kuponadmin.stechomeyazilim.info:9595/deleteDynamicExtraExtraFormMega/${project}`);


export const deleteDynamicFormExtra = project =>
  del(`https://kuponadmin.stechomeyazilim.info:9595/deleteDynamicFormExtraMega/${project}`);

export const deleteCourseSchedules = project =>
  del(`https://kuponadmin.stechomeyazilim.info:9595/deleteCourseSchedulesMega/${project}`);

export const deleteGallery = project =>
  del(`https://kuponadmin.stechomeyazilim.info:9595/deleteGalleryMega/${project}`);

export const deleteReferances = project =>
  del(`https://kuponadmin.stechomeyazilim.info:9595/deleteReferancesMega/${project}`);



  export const getAuthority = () => get(`https://kuponadmin.stechomeyazilim.info:9595/getGroupTypeKupon/select/`);
  export const updateAuth = user => patch(`https://kuponadmin.stechomeyazilim.info:9595/updateGroupTypeKupon/${user.ID}`, user);

  



  

export const deleteNews = project =>
  del(`https://kuponadmin.stechomeyazilim.info:9595/deleteNewsMega/${project}`);

export const deleteMega = project =>
  del(`https://kuponadmin.stechomeyazilim.info:9595/deleteMega/${project}`);

export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

//update mail
export const updateMail = mail => put(url.UPDATE_MAIL, mail);

// get folderlist
export const selectFolders = () => get(url.SELECT_FOLDER);

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message);

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const walletBalanceData = month =>
  get(`${url.GET_WALLET_DATA}/${month}`, { params: { month } });

export const getStatisticData = duration =>
  get(`${url.GET_STATISTICS_DATA}/${duration}`, { params: { duration } });

export const visitorData = duration =>
  get(`${url.GET_VISITOR_DATA}/${duration}`, { params: { duration } });

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS);

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  });
};
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  });
};

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  });
};

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
};