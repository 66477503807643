import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
  UncontrolledTooltip,

} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Date, NameSurname, Status } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import jsPDF from "jspdf";
import "jspdf-autotable";


import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'


import Dropzone from 'react-dropzone-uploader';
import moment from "moment";
import DateModal from "components/Common/DateModal";

import {
  getFeedBack as onGetFeedBack,
  addFeedBack as onAddFeedBack,
  updateFeedBack as onUpdateFeedBack,
  deleteFeedBack as onDeleteFeedBack,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import { Base64 } from "js-base64";


function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file; // loader.file bir Promise nesnesi döndürür

          // Burada file değişkeni Promise'ın çözüldüğü (fulfilled) durumda, yani File nesnesini içerir
          const body = new FormData();
          body.append('dataFile', file);
          console.log("file1c2313",file);

          try {
            const response = await axios.request({
              method: "POST",
              url: `https://berlinadmin.stechomeyazilim.info:9091/uploadfile`,
              data: body, // axios'ta "data" kullanın, "body" değil
              headers: {
                "Content-Type": "multipart/form-data"
              }
            });

            resolve({
              default: `https://megasubdomain.stechomeyazilim.info/${response.data.file.filename}`
            });
          } catch (error) {
            // Hata oluştuğunda throw ile hata fırlatın
            throw error;
          }

          // File nesnesini kullanarak istediğiniz işlemleri yapabilirsiniz
        } catch (error) {
          reject(error);
        }
      });
    },
    abort: () => {}
  };
}


function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}


const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Haberler | TUSF ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();

  const [getHtmlDataLong, setHtmlDataLong] = useState();
  const [getFileSubMedia, setFileSubMedia] = useState([]);

  const [getSubMediaID, setSubMediaID] = useState();

  const [getDateModal, setDateModal] = useState(false);
  const [getIsActive, setIsActive] = useState(false);

  
  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.News_Title) || "",
      desc: (contactEdit && contactEdit.News_ShortDesc) || "",
           
    },
    validationSchema: Yup.object({
 
      title: Yup.string(3).required("Başlık Giriniz"),

    }),
    onSubmit: values => {
      if (isEdit) {
        var localDate = moment().local();


        const updateUser = {
          ID: contactEdit.ID,
          News_Title: values.title,
          News_ImageUrl:getFileName,
          News_ShortDesc:values.desc,
          News_Desc: Base64.encode(getHtmlDataLong),
          IsActive:getIsActive
        };
        console.log("updateUser12",updateUser)
        dispatch(onUpdateFeedBack(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        var localDate = moment().local();

        const newUser = {

          News_Title: values["title"],
          News_ImageUrl:getFileName,
          News_ShortDesc:values["desc"],
          Created_DateTime: localDate,
         News_Desc: Base64.encode(getHtmlDataLong),
         IsActive:getIsActive
        };
        // save new user
        dispatch(onAddFeedBack(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.feedback,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [getCategory, setCategory] = useState([]);
  const [getSelectDate, setSelectDate] = useState(null);
  const [getSelectDateFinish, setSelectDateFinish] = useState(null);
  const [getFilterData, setFilterData] = useState([]);
  const [getFilterCheck, setFilterCheck] = useState(false);
  const [getFileName, setFileName] = useState(null);

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

    if (user.News_Desc != null) {
      setHtmlDataLong(Base64.decode(user.News_Desc))
    }

    
    setFileName(user.News_ImageUrl)

    setIsActive(user.IsActive)
    setIsEdit(true);


    toggle();
  };

  const _deleteLocation = (item) => {
    axios.delete(`https://kuponadmin.stechomeyazilim.info:9595/deleteLocationKupon/${item.ID}`).then(async(res) => {

    console.log("lşmsdfşl",res)

    setTimeout(async() => {

      await axios.get(`https://kuponadmin.stechomeyazilim.info:9595/getCampainLocationFilter/select/${getCampainLocation.ID}`)
      .then((res) => {
        console.log("lsdfk1l",res.data)
        setCoordinatesDefault(res.data)

      })
    }, 2000)

    })
  }

  const handleUserClickMedia = (arg) => {
    const user = arg;

    setSubMediaID(user.ID)

    setFileSubMedia(user.News_Image)

    toggleMedia();
  };


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
    
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.News_ImageUrl ? (
              <div className="avatar-xs">
                {cellProps.News_Title != null ? <span className="avatar-title rounded-circle">
                  {cellProps.News_Title.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.News_ImageUrl}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },

      {
        Header: "Başlık",
        accessor: "News_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Açıklama",
        accessor: "News_ShortDesc",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Aktiflik Durumu",
        accessor: "IsActive",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Oluşturulma Tarihi",
        accessor: "Created_DateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },


      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

<Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickMedia(userData);
                }}
              >
                <i className="mdi mdi-google-photos font-size-18" id="edittooltipMedia" />
                <UncontrolledTooltip placement="top" target="edittooltipMedia">
                  Medya Ekle
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );



  useEffect(() => {
    
    if (users && !users.length) {
      dispatch(onGetFeedBack());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };



  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteFeedBack(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };


  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  
  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }
  
  const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }


  const _onFilterClick = () => {
  
    setFilterData([])

    const dataFilter =  users.filter(x=> x.Created_DateTime >= getSelectDate && x.Created_DateTime <= getSelectDateFinish)
      setFilterData(dataFilter)
      setFilterCheck(true)

      console.log("dataFilter123",dataFilter)
      setDateModal(false)



  // Filtrelenmiş kullanıcıları kullan

  }
  const _addMedia = () => {

    console.log("lşsşmdlf",getFileSubMedia)
    			

    try {
          
      getFileSubMedia.map((item, index) => {
        const newUser = {
          News_Image_Type: item.News_Image_Type,
          News_ID: getSubMediaID,
          Image_Url: item.Image_Url,
        }

        axios.post('https://tusfadmin.stechomeyazilim.info:9098/addNewsImageTusf/send', newUser).then((res) => {

      })
  
        dispatch(onGetFeedBack());
  
      })

      toggleMedia();

      } catch (error) {
        //yield put(updateUserFail(error))
      }

   
  }

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }

  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
    
          if (xhr.readyState === 4) {
            const result = JSON.parse(xhr.response)
            setFileName(result.file.filename)
          }
        
      }
    }
  }

  const _deleteMedia = async (itemData) => {

    try {

      axios.delete(`https://tusfadmin.stechomeyazilim.info:9098/deleteNewsImageTusf/${itemData.ID}`).then(async (res) => {

        await axios.get(`https://tusfadmin.stechomeyazilim.info:9098/getNewsImageTusfSelect/select/${getSubMediaID}`)
          .then((res) => {
            setFileSubMedia(res.data)
          })


      })
    } catch (error) {
      //yield put(updateUserFail(error))
    }


    /*  const filteredData = getFileSubMediaAdd.filter(item => item.ID !== itemData.ID )
      setFileSubMediaAdd(filteredData);*/

  }

  return (
    <React.Fragment>

<DateModal
        onFilterClick = {_onFilterClick}
        show={getDateModal}
        onCloseClick={() => setDateModal(false)}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}
      />
      

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Haberler Listesi" />


   


          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                  <TableContainer
                    columns={columns}
                    data={getFilterCheck == true ? getFilterData : users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddNews={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                  <Modal isOpen={modalMedia} toggle={toggleMedia}>
                    <ModalHeader toggle={toggleMedia} tag="h4">
                      {!!isEdit ? "Düzenle" : "Medya Ekle"}
                    </ModalHeader>
                    <ModalBody>

                      <Row>
                        <Col xs={12}>
                          <div className="mb-3">
                            <label htmlFor="resume">Görsel</label>
                            <Dropzone
                              maxFiles={5}
                              getUploadParams={getUploadParams}
                              multiple={true}
                              onChangeStatus={handleChangeStatus}
                              styles={{
                                dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                inputLabelWithFiles: { margin: '20px 3%' }
                              }}
                              canRemove={true}
                              PreviewComponent={Preview}
                              accept="image/*,video/*"
                            />
                          </div>

                        </Col>
                      </Row>
                      <Row>

                        {getFileSubMedia.map((item, index) => (
                          item.News_Image_Type == "video/mp4" ?
                            <div key={index}>
                              <video width="150" height="150" controls>
                                <source src={'https://megasubdomain.stechomeyazilim.info/' + item.Image_Url} type="video/mp4" />
                              </video>

                              <button
                              onClick={() => _deleteMedia(item)}
                              type="submit"
                              className="btn btn-danger save-user"
                            >
                              Sil
                            </button>
                            </div> :

                            <div key={index}>
                                <button
                              onClick={() => _deleteMedia(item)}
                              type="submit"
                              className="btn btn-danger save-user"
                            >
                              Sil
                            </button>
                              <img src={'https://megasubdomain.stechomeyazilim.info/' + item.Image_Url} alt="" className="avatar-xl" />
                            </div>
                        ))}



                        <Col>
                          <div className="text-end">
                            <button
                              onClick={() => _addMedia()}
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Medyaları Kaydet
                            </button>
                          </div>
                        </Col>
                      </Row>

                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kullanıcı Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>



                            <div className="mb-3">
                              <Label className="form-label">Başlık</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Başlık Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <Row>
                        <Col xs={12}>
                          <div className="mb-3">
                            <label htmlFor="resume">Görsel</label>
                            <Dropzone
                              maxFiles={5}
                              getUploadParams={getUploadParams}
                              multiple={true}
                              onChangeStatus={handleChangeStatus}
                              styles={{
                                dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                inputLabelWithFiles: { margin: '20px 3%' }
                              }}
                              canRemove={true}
                              PreviewComponent={Preview}
                              accept="image/*,video/*"
                            />
                          </div>

                        </Col>
                      </Row>

                      {getFileName != null ? <Row>
                        <Col xs={12}>
                      <div >
                              <img src={'https://megasubdomain.stechomeyazilim.info/' + getFileName} alt="" className="avatar-xl" />
                            </div>
                            </Col>
                            </Row> : null}

                            <div className="mb-3">
                              <Label className="form-label">Kısa Açıklama</Label>
                              <Input
                                name="desc"
                                label="desc"
                                type="text"
                                placeholder="Açıklama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.desc || ""}
                                invalid={
                                  validation.touched.desc &&
                                    validation.errors.desc
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.desc &&
                                validation.errors.desc ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.desc}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Uzun Açıklama</Label>

                              <Form method="post">
                                <CKEditor
                                  editor={Editor}
                                  data={getHtmlDataLong}
                                  config={{
                                    // @ts-ignore
                                    extraPlugins: [uploadPlugin],
                                  }}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setHtmlDataLong(data)

                                  }}
                                />
                              </Form>
                            </div>

                            <div className="d-flex">
                          <label htmlFor="resume">Aktif Mi?</label>

                          <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                         
                        </div>

                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
