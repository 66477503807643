import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Email, Date, Status } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getTeacherMega  as onGetTeacher,
  addAbout2 as onAddAbout2,
  updateAbout2 as onUpdateAbout2,
  deleteAbout2 as onDeleteAbout2,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import Dropzone from 'react-dropzone-uploader';


import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";

const ContactsList = props => {

  //meta title
  document.title = "HAKKIMIZDA | TUSF ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getEditContactTeacher, setEditContactTeacher] = useState();
  const [getIsActive, setIsActive] = useState(false);
  const [getFileName, setFileName] = useState(null);
  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);


  const _getAuthData = async () => {

    await axios.get(`https://tusfadmin.stechomeyazilim.info:9098/getAbout2CategoryTusf/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }


  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (getEditContactTeacher && getEditContactTeacher.About2_Title) || "",
      desc: (getEditContactTeacher && getEditContactTeacher.About2_Desc) || "",
      sort: (getEditContactTeacher && getEditContactTeacher.Sort) || "",
       },
    validationSchema: Yup.object({
    
    }),
    onSubmit: values => {
      if (isEdit) {
   					

        console.log("şömsdfşl",values)
        const updateUser = {
          ID: contact.ID,
          About2_Image: getFileName,
          About2_Title: values.title,
          Sort: parseInt(values.sort),
          About2_Desc: values.desc,
          About2_Category_ID: getCategoryID,
          IsActive:getIsActive
        };
        // update user
        dispatch(onUpdateAbout2(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {

          About2_Image: getFileName,
          About2_Title: values["title"],
          Sort: parseInt(values.sort),
          About2_Desc: values["desc"],
          About2_Category_ID: getCategoryID,
          IsActive:getIsActive

        };
        // save new user
        dispatch(onAddAbout2(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.teacher,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
    
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.About2_Image ? (
              <div className="avatar-xs">
                {cellProps.About2_Title != null ? <span className="avatar-title rounded-circle">
                  {cellProps.About2_Title.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.About2_Image}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },

      {
        Header: "Başlık",
        accessor: "About2_Title",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Sıralama",
        accessor: "Sort",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "Kategori",
        accessor: "About2_Category.About2_Category_Title",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
     
      {
        Header: "Aktiflik Durumu",
        accessor: "IsActive",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
   
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

         

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleDeleteUser(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }


  useEffect(() => {
    _getAuthData()

    if (users && !users.length) {
      dispatch(onGetTeacher());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setEditContactTeacher(user)
    setContact(user);
    setIsEdit(true);

    setIsActive(user.IsActive)
    setCategoryID(user.About2_Category_ID)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);


  const handleDeleteUser = (data) => {
    console.log("msdkfşl",data)
    if (data && data.ID) {
      dispatch(onDeleteAbout2(data.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }

  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response)
          setFileName(result.file.filename)
        }
      }
    }
  }

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Kullanıcı" breadcrumbItem="HAKKIMIZDA" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddTeacher={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Hakkımızda Düzenle" : "Hakkımızda Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >      

                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Başlık</Label>
                              <Input
                                name="title"
                                type="text"
                                placeholder="Başlık"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                     
                       
                     

                    
                          </Col>

                         
                        <Col xs={12}>


                          <div className="mb-3">
                            <label htmlFor="resume">Görsel</label>
                            <Dropzone
                              maxFiles={5}
                              getUploadParams={getUploadParams}
                              multiple={true}
                              onChangeStatus={handleChangeStatus}
                              styles={{
                                dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                inputLabelWithFiles: { margin: '20px 3%' }
                              }}
                              canRemove={true}
                              PreviewComponent={Preview}
                              accept="image/*,video/*"
                            />
                          </div>

                         { getFileName != null ? <div className="mb-3">
                          <img
                  className="avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' + getFileName}
                  alt=""
                />
                </div> : null}
                        </Col>
                     
                        <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Sıralama</Label>
                              <Input
                                name="sort"
                                type="number"
                                placeholder="Sıralama"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sort || ""}
                                invalid={
                                  validation.touched.sort &&
                                    validation.errors.sort
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.sort &&
                                validation.errors.sort ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.sort}
                                </FormFeedback>
                              ) : null}
                            </div>
                     
                       
                        
                    
                          </Col>

                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Kısa Açıklama</Label>
                              <Input
                                name="desc"
                                type="text"
                                placeholder="Kısa Açıklama"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.desc || ""}
                                invalid={
                                  validation.touched.desc &&
                                    validation.errors.desc
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.desc &&
                                validation.errors.desc ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.desc}
                                </FormFeedback>
                              ) : null}
                            </div>
                     
                       
                            <Col lg={6}>
                          
                            <div className="mb-3">
                              <Label className="form-label">Kategori Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                <option value="">Lütfen Seçim Yapınız</option>
                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.About2_Category_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <div className="square-switch">

<input
  type="checkbox"
  id="square-switch1"
  className="switch"
  defaultChecked={getIsActive}
  onChange={() =>
    setIsActive(!getIsActive)
  }
/>
<label
htmlFor="square-switch1"
  data-on-label="Aktif "
  data-off-label="Pasif"
/>
</div>

                          </Col>


                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
