import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Date, NameSurname, Status } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import jsPDF from "jspdf";
import "jspdf-autotable";

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import Dropzone from 'react-dropzone-uploader';
import { Base64 } from 'js-base64';

import {
  getCampain as onGetCampain,
  addCampain as onAddCampain,
  updateCampain as onUpdateCampain,
  deleteCampain as onDeleteCampain,
  addCampainSuccess,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file; // loader.file bir Promise nesnesi döndürür

          // Burada file değişkeni Promise'ın çözüldüğü (fulfilled) durumda, yani File nesnesini içerir
          const body = new FormData();
          body.append('dataFile', file);
          console.log("file1c2313",file);

          try {
            const response = await axios.request({
              method: "POST",
              url: `https://berlinadmin.stechomeyazilim.info:9091/uploadfile`,
              data: body, // axios'ta "data" kullanın, "body" değil
              headers: {
                "Content-Type": "multipart/form-data"
              }
            });

            resolve({
              default: `https://megasubdomain.stechomeyazilim.info/${response.data.file.filename}`
            });
          } catch (error) {
            // Hata oluştuğunda throw ile hata fırlatın
            throw error;
          }

          // File nesnesini kullanarak istediğiniz işlemleri yapabilirsiniz
        } catch (error) {
          reject(error);
        }
      });
    },
    abort: () => {}
  };
}


function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}


const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "KAMPANYA | KUPON ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getFileName, setFileName] = useState(null);
  const [getFileName2, setFileName2] = useState(null);


  const [getHtmlDataLong, setHtmlDataLong] = useState();
  const [getIsActive, setIsActive] = useState(false);
  const [getIsService, setIsService] = useState(false);
  const [getIsActiveStories, setIsActiveStories] = useState(false);
  const [getCategoryCompany, setCategoryCompany] = useState([]);
  const [selectedValuesCompany, setSelectedValuesCompany] = useState([]);
  const [getSelectedValuesCompanyDefault, setSelectedValuesCompanyDefault] = useState([]);

  const { campainDefault } = useSelector(state => ({
    campainDefault: state.contacts.campainDefault,
  }));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Campain_Title) || "",

      link: (contactEdit && contactEdit.Campain_Link) || "",

      discount: (contactEdit && contactEdit.Discount) || "",
      sort: (contactEdit && contactEdit.Sort) || "",


      com: (contactEdit && contactEdit.CommissionPercentage) || "",
      maks: (contactEdit && contactEdit.Campain_Maks_Users) || "",
      min: (contactEdit && contactEdit.Campain_MinUsers) || "",
      shortDesc: (contactEdit && contactEdit.Campain_ShortDesc) || "",
      code:(contactEdit && contactEdit.Campain_Code) || "",
     
    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),
      sort: Yup.number().required("Sıralama Numarası Giriniz"),

    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Campain_Title: values.title,
          Campain_Desc: Base64.encode(getHtmlDataLong),
          Campain_SubCategory_ID: getCategoryID,
          Campain_Status_ID: getCategoryIDThird,
          CampainPeriod_ID: getCategoryIDForth,
          Campain_Photo: getFileName,
          Compain_Active: getIsActive,
          IsTheCampaignAddedByTheServiceUser: getIsService,
          Campain_Link: values.link != null ? values.link : null,
          Campain_MinUsers: values.min != null ? parseInt(values.min) : null,
          Campain_Maks_Users: values.maks != null ? parseInt(values.maks) : null,
          CommissionPercentage:  values.com != null ? values.com : null,
          Sort: values.sort,
          Discount: values.discount,
          StoriesCheck:getIsActiveStories,
          Campain_ShortDesc: values.shortDesc,
          Campain_DescImage : getFileName2,
          Campain_Code :values.code != null ? values.code : null,
        
        }

        console.log("msdklşf123",getSelectedValuesCompanyDefault)

  if(getSelectedValuesCompanyDefault.length > 0){

        axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteCampainCompanyKupon/${contactEdit.ID}`).then(async(res) => {
       
        console.log("lsdlf",res)
        setTimeout(() => {

          console.log("selectedValuesCompany1231",selectedValuesCompany)
          selectedValuesCompany.map((item, index) => {
    
            console.log("msdfcl11ş",item,contactEdit.ID)
    
           const newUser2 = {
             Campain_ID:contactEdit.ID,
             Company_ID:item
           }
     
           axios.post('https://getjob.stechomeyazilim.info:5101/addCompanyCampain/send', newUser2).then(response => {
           })
    
         }) 
    
        }, 1000)

      })


   

  }else{

    console.log("selny1231",selectedValuesCompany)

    selectedValuesCompany.map((item, index) => {

      console.log("msdfc123l11ş",item)

     const newUser2 = {
       Campain_ID:contactEdit.ID,
       Company_ID:item
     }

     axios.post('https://getjob.stechomeyazilim.info:5101/addCompanyCampain/send', newUser2).then(response => {
     })

   }) 
  }


       
        dispatch(onUpdateCampain(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        
        const newUser = {
          Campain_Title: values["title"],
          Campain_Desc: Base64.encode(getHtmlDataLong),
          Campain_SubCategory_ID: getCategoryID,
          Campain_Status_ID: getCategoryIDThird,
          CampainPeriod_ID: getCategoryIDForth,
          Campain_Link: values["link"],
          IsTheCampaignAddedByTheServiceUser: getIsService,
          Campain_Photo: getFileName,
          Compain_Active: getIsActive,
          Campain_MinUsers: values["min"],
          Campain_Maks_Users: values["maks"],
          CommissionPercentage: values["com"],
          Sort: values["sort"],
          Discount: values["discount"],
          Created_DateTime: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
          StoriesCheck:getIsActiveStories,
          Campain_ShortDesc: values["shortDesc"],
          Campain_DescImage : getFileName2,
          Campain_Code : values["code"],
        
        }
        /*    */

        dispatch(onAddCampain(newUser));
       // validation.resetForm();

      
        setTimeout(() => {
          
     selectedValuesCompany.map((item, index) => {
   
        if(campainDefault.length > 0){
          const newUser2 = {
            Campain_ID:parseInt(campainDefault[0].ID) + 1,
            Company_ID:item
          }
     
          axios.post('https://getjob.stechomeyazilim.info:5101/addCompanyCampain/send', newUser2).then(response => {
          })
        
        }
          
   
         }) 
    
        }, 4600)



      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.campain,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalLocation, setModalLocation] = useState(false);
  const [getCampainLocation, setCampainLocation] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [coordinates, setCoordinates] = useState([{ lat: '', long: '' }]);

  // Yeni bir enlem ve boylam alanı ekler
  const addCoordinateField = () => {
    setCoordinates([...coordinates, { lat: '', long: '' }]);
  }


  const _addLocation = () => {
  
    coordinates.map(item => {

      const location = {
        Campain_ID:contactEdit.ID,
        Lat:item.lat,
        Long:item.long
      }

      axios.post('https://getjob.stechomeyazilim.info:5101/addPostLocationKupon/send', location).then(response => {
      })
}
    )

    toggleLocation()

  }
  
  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategoryIDSecond, setCategoryIDSecond] = useState(null);
  const [getCategoryIDThird, setCategoryIDThird] = useState(null);
  const [getCategoryIDForth, setCategoryIDForth] = useState(null);


  const [getCategory, setCategory] = useState([]);
  const [getCategorySecond, setCategorySecond] = useState([]);
  const [getCategoryThird, setCategoryThird] = useState([]);
  const [getCategoryForth, setCategoryForth] = useState([]);
  const [getCategoryFifth, setCategoryFifth] = useState([]);
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "ID",
        accessor: "ID",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Campain_Photo ? (
              <div className="avatar-xs">
                {cellProps.Campain_Title != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Campain_Title.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.Campain_Photo}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Başlık",
        accessor: "Campain_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

     
/** 
      {
        Header: "Firma",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
          {cellProps.Campain_Company != null ? cellProps.Campain_Company.map((item, index) => (
        <option value={item.ID} key={item.ID}>{item.Company.Company_Title}</option>
      )) : null}
             
          </>
        ),
      },*/

    
      {
        Header: "Kampanya Dönemi",
        accessor: "Campaign_Period.Campaign_Period_Name",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
     

      {
        Header: "Kampanya Baş. Dönemi",
        accessor: "Campaign_Period.StartDate",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },

      {
        Header: "Kampanya Bit. Dönemi",
        accessor: "Campaign_Period.FinishDate",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },

      {
        Header: "Kampanya Durumu",
        accessor: "Campain_Status.Campain_Status_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      

      {
        Header: "Görüntülenme Sayısı",
        filterable: true,
        accessor: (cellProps) => (
          <>
          
              <div>
                <a>
                {cellProps.Campain_Seen != null ? cellProps.Campain_Seen.length : 0}
                </a>
              </div>
     
          </>
        ),

        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Fav Sayısı",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
          
              <div>
                <a>
                {cellProps.Users_FavoriteCampain != null ? cellProps.Users_FavoriteCampain.length : 0}
                </a>
              </div>
     
          </>
        ),
      },
      {
        Header: "Aktif Mi?",
        accessor: "Compain_Active",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },

      {
        Header: "Sıralama",
        accessor: "Sort",
        filterable: false,
        disableFilters:true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

<Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickLocation(userData);
                }}
              >
                <i className="mdi mdi-checkbox-multiple-blank-circle-outline font-size-18" id="edittooltiplocation" />
                <UncontrolledTooltip placement="top" target="edittooltiplocation">
                  Konum
                </UncontrolledTooltip>
              </Link>


              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getSubCategoryKupon/select/`)
      .then((res) => {
        setCategory(res.data)
      })


  }

  const _getAuthDataSecond = async () => {


    await axios.get(`https://getjob.stechomeyazilim.info:5101/getCompanyKupon/select/`)
      .then((res) => {
        setCategorySecond(res.data)
      })
  }


  const _getAuthDataThird = async () => {


    await axios.get(`https://getjob.stechomeyazilim.info:5101/getCampain_StatusKupon/select/`)
      .then((res) => {
        setCategoryThird(res.data)
      })
  }

  const _getAuthDataForth = async () => {


    await axios.get(`https://getjob.stechomeyazilim.info:5101/getCampaign_PeriodKupon/select/`)
      .then((res) => {
        setCategoryForth(res.data)
      })
  }


  const _getAuthDataFifth = async () => {


    await axios.get(`https://getjob.stechomeyazilim.info:5101/getSubSectorKupon/select/`)
      .then((res) => {
        setCategoryFifth(res.data)
      })
  }


  const _getAuthDataCompany = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getCompanyKuponSearch/select/`)
      .then((res) => {

        const sortedData = res.data.sort((a, b) => {
          const nameA = a.Company_Title.toUpperCase(); // Büyük/küçük harfe duyarlı sıralama yapmak için büyük harfe dönüştürme
          const nameB = b.Company_Title.toUpperCase();

          if (nameA < nameB) {
              return -1; // a, b'den önce gelmeli
          }
          if (nameA > nameB) {
              return 1; // a, b'den sonra gelmeli
          }

          return 0; // Eşit, sıralamada değişiklik yapma
      });

      // Sıralanmış verileri state'e set et
      setCategoryCompany(sortedData);

      })
  }



  useEffect(() => {
    _getAuthDataCompany()
    _getAuthData()
    _getAuthDataSecond()
    _getAuthDataThird()
    _getAuthDataForth()
    _getAuthDataFifth()

    console.log("isEdit123",isEdit)
    if(isEdit==false){
     // setHtmlDataLong(null)
      setSelectedValuesCompany([])
    }
    if (users && !users.length) {
      dispatch(onGetCampain());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleLocation = () => {
    setModalLocation(!modalLocation);
  };

  
  const handleUserClickLocation = (arg) => {
    const user = arg;

    setCampainLocation(arg)
    toggleLocation()
  }

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

  
  //  const selectedOptions = Array.from(user.Campain_Company, (option) => option.ID);
  const selectedCompanyIDs = user.Campain_Company.map(company => company.Company_ID.toString());
  setSelectedValuesCompany(selectedCompanyIDs);

  setSelectedValuesCompanyDefault(user.Campain_Company);
 
    setFileName(user.Campain_Photo)
    setCategoryID(user.Campain_SubCategory_ID)
    setCategoryIDThird(user.Campain_Status_ID)

    setCategoryIDForth(user.CampainPeriod_ID)
    setIsActiveStories(user.StoriesCheck)

    setHtmlDataLong(Base64.decode(user.Campain_Desc))

    setFileName2(user.Campain_DescImage)

      setIsActive(user.Compain_Active)

      setIsService(user.IsTheCampaignAddedByTheServiceUser)
  

    setIsEdit(true);


    toggle();
  };


  const handleSelectChangeCompany = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    console.log("klnsdf",selectedOptions)
    setSelectedValuesCompany(selectedOptions); // Seçilen değerleri durum olarak güncelleyin
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteCampain(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

 
  const _setChoiceCategoryThird = arg => {
    setCategoryIDThird(arg.target.value)
  }

  const _setChoiceCategoryForth = arg => {
    setCategoryIDForth(arg.target.value)
  }

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }



  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }


  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }

  const handleChangeStatus2 = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName2(result.file.filename)

        }
      }
    }
  }  
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);


  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Kullanıcının girdiği metne göre seçenekleri filtrele
    const filtered = getCategoryCompany.filter(option =>
        option.Company_Title.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredOptions(filtered);
};


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Kampanya Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddCampain={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

             
                  <Modal  size="lg" isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kampanya Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          

                          <Col lg={4}>
                          <div className="mb-3">
                              <Label className="form-label">Başlık</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Kampanya Başlığını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Kısa Açıklama</Label>
                              <Input
                                name="shortDesc"
                                label="shortDesc"
                                type="text"
                                placeholder="Kısa Açıklama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.shortDesc || ""}
                                invalid={
                                  validation.touched.shortDesc &&
                                    validation.errors.shortDesc
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.shortDesc &&
                                validation.errors.shortDesc ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.shortDesc}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Kampanya Kod</Label>
                              <Input
                                name="code"
                                label="code"
                                type="text"
                                placeholder="Kodu Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.code || ""}
                                invalid={
                                  validation.touched.code &&
                                    validation.errors.code
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.code &&
                                validation.errors.code ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.code}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <div className="mb-3">
                              <Label className="form-label">Uzun Açıklama</Label>

                              <Form method="post">
                                <CKEditor
                                  editor={Editor}
                                  config={{
                                    // @ts-ignore
                                    extraPlugins: [uploadPlugin],
                                  }}
                                  data={getHtmlDataLong}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setHtmlDataLong(data)

                                  }}
                                />
                              </Form>
                            </div>


                          
                              <Col lg={6} className="mb-3">
                                <label htmlFor="resume">Kapak Görsel</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

{getFileName != null && isEdit == true ? 
<div>
                <img
                  className="avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' + getFileName}
                  alt=""
                />
              </div> : null}
                              </Col>
                           

                          
                          


              <Col lg={6} className="mb-3">
                                <label htmlFor="resume">Detay Görsel</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus2}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

{getFileName2 != null && isEdit == true ? 
<div>
                <img
                  className="avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' + getFileName2}
                  alt=""
                />
              </div> : null}

                              </Col>
                           

            


                            <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Kampanya Sektörü</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                    <option value="">Kampanya Sektörü Seçiniz</option>

                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Campain_SubCategory_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>
                            </Col>


                            <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Kampanya Dönemi Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryIDForth}
                                onChange={(text) => _setChoiceCategoryForth(text)}
                                value={
                                  getCategoryIDForth
                                }>
                                <option value="">Lütfen Seçim Yapınız</option>

                                {getCategoryForth.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Campaign_Period_Name}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">İndirim Yüzdesi</Label>
                              <Input
                                name="discount"
                                label="discount"
                                type="text"
                                placeholder="İndirim Yüzdesi Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.discount || ""}
                                invalid={
                                  validation.touched.discount &&
                                    validation.errors.discount
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.discount &&
                                validation.errors.discount ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.discount}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>


                      
    {/*çoktan seçmeli firmalar*/}

    <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Kampanya Durumu Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryIDThird}
                                onChange={(text) => _setChoiceCategoryThird(text)}
                                value={
                                  getCategoryIDThird
                                }>
                               <option value="">Lütfen Seçim Yapınız</option>

                                {getCategoryThird.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Campain_Status_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                         
                            <Col lg={4}>
                         
                            <div className="mb-3">
                              <Label className="form-label">Kişi başı maks. kul. sayısı giriniz</Label>
                              <Input
                                name="min"
                                label="min"
                                type="text"
                                placeholder="Kişi başı maks. kul. sayısı giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.min || ""}
                                invalid={
                                  validation.touched.min &&
                                    validation.errors.min
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.min &&
                                validation.errors.min ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.min}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                           

                            <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Maksimum kullanım sayısı</Label>
                              <Input
                                name="maks"
                                label="maks"
                                type="text"
                                placeholder="Maksimum kullanıcı sayısıGiriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.maks || ""}
                                invalid={
                                  validation.touched.maks &&
                                    validation.errors.maks
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.maks &&
                                validation.errors.maks ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.maks}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                          


                            <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Komisyon yüzdesi giriniz</Label>
                              <Input
                                name="com"
                                label="com"
                                type="com"
                                placeholder="Komisyon yüzdesi sayısıGiriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.com || ""}
                                invalid={
                                  validation.touched.com &&
                                    validation.errors.com
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.com &&
                                validation.errors.com ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.com}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Yönlendirme Linki Giriniz</Label>
                              <Input
                                name="link"
                                label="link"
                                type="text"
                                placeholder="Yönlendirme Linki Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.link || ""}
                                invalid={
                                  validation.touched.link &&
                                    validation.errors.link
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.link &&
                                validation.errors.link ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.link}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>


                            <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Kampanya Sıra No Giriniz</Label>
                              <Input
                                name="sort"
                                label="sort"
                                type="text"
                                placeholder="Kampanya Sıra No  Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sort || ""}
                                invalid={
                                  validation.touched.sort &&
                                    validation.errors.sort
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.sort &&
                                validation.errors.sort ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.sort}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>


                            <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Enlem Giriniz</Label>
                              <Input
                                name="lat"
                                label="lat"
                                type="text"
                                placeholder="Enlem Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lat || ""}
                                invalid={
                                  validation.touched.lat &&
                                    validation.errors.lat
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.lat &&
                                validation.errors.lat ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.lat}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>


                            <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label">Boylam Giriniz</Label>
                              <Input
                                name="long"
                                label="long"
                                type="text"
                                placeholder="Boylam Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.long || ""}
                                invalid={
                                  validation.touched.long &&
                                    validation.errors.long
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.long &&
                                validation.errors.long ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.long}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col lg={12}>
                          
                            <div className="mb-3">
                              <Label className="form-label">Firma Seçiniz</Label>

                              <div className="mb-3">
                              <input
                type="text"
                className="form-control"
                placeholder="Arama yapın..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            </div>
            
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={selectedValuesCompany}
                                multiple
                                onChange={(text) => handleSelectChangeCompany(text)}
                                value={
                                  selectedValuesCompany
                                }>
                                {filteredOptions.length > 0 ? filteredOptions.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Company_Title} - {item.Users.length}</option>
                          )) :  getCategoryCompany.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Company_Title} - {item.Users.length}</option>
                          ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                  
                            <Col lg={4}>
                            <div className="d-flex">
                              <label htmlFor="resume">Hizmet alan tarafından eklenen kampanya mı?</label>

                              <div className="square-switch2">

                                <input
                                  type="checkbox"
                                  id="square-switch2"
                                  className="switch"
                                  defaultChecked={getIsService}
                                  onChange={() =>
                                    setIsService(!getIsService)
                                  }
                                />
                                <label
                                  htmlFor="square-switch2"
                                  data-on-label="Evet "
                                  data-off-label="Hayır"
                                />
                              </div>
                              </div>

                            </Col>
                          

                 

                            <Col lg={4}>
                            <div className="d-flex">
                              <label htmlFor="resume">Stories?</label>

                              <div className="square-switch3">

                                <input
                                  type="checkbox"
                                  id="square-switch3"
                                  className="switch"
                                  defaultChecked={getIsActiveStories}
                                  onChange={() =>
                                    setIsActiveStories(!getIsActiveStories)
                                  }
                                />
                                <label
                                  htmlFor="square-switch3"
                                  data-on-label="Evet "
                                  data-off-label="Hayır"
                                />
                              </div>
                              </div>
                            </Col>
                            

                            <Col lg={4}>
                            <div className="d-flex">
                              <label htmlFor="resume">Aktif Mi?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  className="switch"
                                  defaultChecked={getIsActive}
                                  onChange={() =>
                                    setIsActive(!getIsActive)
                                  }
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label="Aktif "
                                  data-off-label="Pasif"
                                />
                              </div>

                            </div>
                            </Col>


                         
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                  <Modal  size="lg" isOpen={modalLocation} toggle={toggleLocation}>
                    <ModalHeader toggle={toggleLocation} tag="h4">
                      {!!isEdit ? "Düzenle" : "Konum Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          _addLocation()
                        }}
                      >
      {coordinates.map((coord, index) => (
           <Row key={index}>
        <div key={index}>
          <Col lg={4}>
            <div className="mb-3">
              <Label className="form-label">Enlem Giriniz</Label>
              <Input
                name={`lat-${index}`}
                label="lat"
                type="text"
                placeholder="Enlem Giriniz"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values[`lat-${index}`] || ""}
                invalid={
                  validation.touched[`lat-${index}`] &&
                  validation.errors[`lat-${index}`]
                    ? true
                    : false
                }
              />
              {validation.touched[`lat-${index}`] &&
                validation.errors[`lat-${index}`] ? (
                <FormFeedback type="invalid">
                  {validation.errors[`lat-${index}`]}
                </FormFeedback>
              ) : null}
            </div>
          </Col>

          <Col lg={4}>
            <div className="mb-3">
              <Label className="form-label">Boylam Giriniz</Label>
              <Input
                name={`long-${index}`}
                label="long"
                type="text"
                placeholder="Boylam Giriniz"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values[`long-${index}`] || ""}
                invalid={
                  validation.touched[`long-${index}`] &&
                  validation.errors[`long-${index}`]
                    ? true
                    : false
                }
              />
              {validation.touched[`long-${index}`] &&
                validation.errors[`long-${index}`] ? (
                <FormFeedback type="invalid">
                  {validation.errors[`long-${index}`]}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </div>
        </Row>
      ))}

      {/* Yeni enlem ve boylam alanı eklemek için bir düğme */}
      <Button color="primary" onClick={addCoordinateField}>+ Yeni Enlem ve Boylam Ekle</Button>
                         
                   
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
